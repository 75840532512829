import dayjs from "dayjs";
import * as R from "ramda";
import {
    parsePhoneNumberFromString,
    parseIncompletePhoneNumber,
} from "libphonenumber-js";

export const validatePhoneNumber = (rule, value) => {
    if (value && value !== "") {
        try {
            const phoneNumber = parsePhoneNumberFromString(value, "MY");

            if (phoneNumber && phoneNumber.isValid()) {
                return Promise.resolve();
            }

            return Promise.reject("Invalid Phone Number!");
        } catch (err) {
            return Promise.reject(err);
        }
    }

    return Promise.resolve();
};

export const formatPhoneNumber = value => {
    if (!value) {
        return value;
    }

    const formattedNumber = parseIncompletePhoneNumber(value);

    try {
        const phoneNumber = parsePhoneNumberFromString(formattedNumber, "MY");

        if (phoneNumber && phoneNumber.isValid() === true) {
            return phoneNumber.format("E.164");
        }
    } catch (err) {
        return formattedNumber;
    }

    return formattedNumber;
};

export const getActiveCentre = (centreUuid, centres) => {
    const activeCentre = centres.find(centre => {
        return centre.uuid === centreUuid;
    });

    return activeCentre;
};

export function getTimeDistance(type) {
    const now = dayjs();
    if (type === "today") {
        return [dayjs(now).startOf("day"), dayjs(now).endOf("day")];
    }

    if (type === "week") {
        return [dayjs(now).startOf("week"), dayjs(now).endOf("week")];
    }

    if (type === "month") {
        return [dayjs(now).startOf("month"), dayjs(now).endOf("month")];
    }

    if (type === "year") {
        return [dayjs(now).startOf("year"), dayjs(now).endOf("year")];
    }
}

export function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        const context = this,
            args = arguments;
        const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const sortBookings = bookings => {
    const filteredBookings = bookings.map(obj => ({
        start: obj.start,
        end: obj.end,
        courtName: obj.court.name,
        price: obj.price,
        uuid: obj.uuid,
    }));

    const groupedBookings = [];
    filteredBookings.forEach(function(item) {
        if (groupedBookings.length === 0) {
            groupedBookings.push(item);
        } else {
            let dIndex = -1;
            groupedBookings.forEach((itm, idx) => {
                if (item.start === itm.start && item.end === itm.end)
                    dIndex = idx;
            });

            if (dIndex !== -1) {
                const oldValue = groupedBookings[dIndex].courtName;
                const oldAmount = groupedBookings[dIndex].price;
                const oldUUID = groupedBookings[dIndex].uuid;

                if (typeof oldValue.toString() === "string") {
                    groupedBookings[dIndex].courtName =
                        oldValue + ", " + item.courtName;
                    groupedBookings[dIndex].price = oldAmount + item.price;
                    groupedBookings[dIndex].uuid = oldUUID + item.uuid;
                }
            } else {
                groupedBookings.push(item);
            }
        }
    });
    const sortedBookings = R.sort(R.ascend(R.prop("start")), groupedBookings);
    return sortedBookings;
};

export const convertNumberToRinggitString = number => {
    if (!number && number !== 0) {
        return;
    }

    if (number === 0) {
        return "RM 0";
    }
    const numberString = new Intl.NumberFormat("en-US").format(number);
    const currencyString = "RM " + numberString;
    return currencyString;
};

export const getActiveMenuKey = pathname => {
    const activeMenuKey = pathname.split(/(?=\/)/);
    return activeMenuKey;
};
