// eslint-disable-next-line
import React from "react";
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";

import { Logomark } from "assets/vectors/Logo";

const LoadingPage = () => {
    return (
        <div css={styles.container}>
            <div css={styles.logoDiv}>
                <Logomark css={styles.logo} />
            </div>
        </div>
    );
};

const logoRotate = keyframes`
    0 {
        transform: "rotateY(0)";
    }
    50% {
        transform: rotateY(180deg);
    };
    100% {
        transform: rotateY(0deg);
    };
`;

const styles = {
    container: css`
        height: 100vh;
        width: 100vw;
        background-color: #6781be;
    `,
    logoDiv: css`
        top: 50%;
        left: 50%;
        position: absolute;
        fontsize: 24px;
        fontweight: 500;
        textalign: center;
        transformstyle: preserve-3d;
        transform: translate(-50%, -50%);
    `,
    logo: css`
        height: 64px;
        color: #ffffff;
        animation: ${logoRotate} 3s both infinite;
    `,
};

export default LoadingPage;
