import React from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Spin, Result, Button } from "antd";
import dayjs from "dayjs";
import CourtsiteScheduler from "components/CourtsiteScheduler/index";
import * as R from "ramda";

import { useModal } from "components/Modals/ModalProvider";

const GET_BOOKINGS_FOR_DATE = gql`
    query getBookingsForDate(
        $centre_uuid: ID!
        $start: DateTime!
        $end: DateTime!
    ) {
        me {
            getBookingsForDate(
                centre_uuid: $centre_uuid
                start: $start
                end: $end
            ) {
                uuid
                court {
                    uuid
                    name
                }
                start
                end
                price
                reference_id
                user {
                    name
                    email
                    phone_number
                }
                invoice {
                    uuid
                    status
                    user {
                        name
                        email
                        phone_number
                    }
                }
                notes
                status
                source
                isInCart @client
            }
        }
    }
`;

const Scheduler = ({
    schedulerStartTime,
    schedulerEndTime,
    activeCentre,
    activeTabKey,
}) => {
    const { initModal } = useModal();

    const { loading, error, data } = useQuery(GET_BOOKINGS_FOR_DATE, {
        variables: {
            centre_uuid: activeCentre.uuid,
            start: dayjs(schedulerStartTime).toISOString(),
            end: dayjs(schedulerEndTime).toISOString(),
        },
        fetchPolicy: "cache-and-network",
        pollInterval: 600000,
    });

    const activeResources = activeCentre.courts.filter(court => {
        return court.sport === activeTabKey;
    });

    const bookings = (data && data.me && data.me.getBookingsForDate) || [];

    const activeBookings = bookings.filter(booking => {
        return booking.status !== "CANCELLED";
    });

    const bookingsWithStatusColor = activeBookings.map(booking => {
        return {
            ...booking,
            statusColor:
                booking.status === "ACTIVE"
                    ? "#5b8ff9"
                    : booking.invoice
                        ? "#7ACCAA"
                        : "#ff99c3",
        };
    });

    const handleTimeSlotSelect = (start, end, resourceID) => {
        initModal("createBooking", {
            courts: activeResources,
            courtID: resourceID,
            start: start,
            end: end,
        });
    };

    return (
        <Spin spinning={loading}>
            {error ? (
                <Result
                    status="error"
                    title="Some error occured"
                    subTitle="Please try again."
                    extra={
                        <Button
                            danger
                            type="primary"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Refresh
                        </Button>
                    }
                />
            ) : (
                <CourtsiteScheduler
                    range={{ start: schedulerStartTime, end: schedulerEndTime }}
                    resources={toSchedulerResourceFormat(activeResources)}
                    events={bookingsWithStatusColor}
                    handleTimeSlotSelect={handleTimeSlotSelect}
                />
            )}
        </Spin>
    );
};

export default Scheduler;

const toSchedulerResourceFormat = courts => {
    const schedulerResources = (courts || []).map(court => ({
        id: court.uuid,
        name: court.name,
    }));

    const sortedResources = R.sort((a, b) => {
        if (a.name[0] < b.name[0]) {
            return -1;
        } else if (a.name[0] > b.name[0]) {
            return 1;
        } else if (a.name.length < b.name.length) {
            return -1;
        } else if (a.name.length > b.name.length) {
            return 1;
        } else if (a.name < b.name) {
            return -1;
        } else if (a.name > b.name) {
            return 1;
        } else {
            return 0;
        }
    }, schedulerResources);

    return sortedResources;
};
