// eslint-disable-next-line
import React, { useState } from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Layout, Menu } from "antd";

import { centreRoutes } from "routes/indexRoutes";

import { useCentre } from "hooks/centreConfig";

import Header from "components/Header/Header";

import { getActiveMenuKey } from "utils/utils";

const styles = {
    body: css`
        min-height: calc(100vh - 64px);
        margin-top: 64px;
    `,
    content: css`
        padding: 16px;

        @media only screen and (max-width: 575px) {
            padding: 16px 0;
        }
    `,
};

const HeaderSiderLayout = ({ children }) => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const { activeCentre, centres } = useCentre();
    const [sidebarHovered, setSidebarHovered] = useState(false);

    const sidebarMouseHoverStart = () => {
        setSidebarHovered(true);
    };

    const sidebarMouseHoverEnd = () => {
        setSidebarHovered(false);
    };

    const handleMenuClick = ({ key }) => {
        const newPathname = match.url + key;

        history.push(newPathname);
    };

    return (
        <Layout>
            <Header activeCentre={activeCentre} centres={centres} />
            <Layout css={styles.body} hasSider>
                <Layout.Sider
                    width={sidebarHovered ? 256 : 80}
                    breakpoint="lg"
                    collapsedWidth={0}
                    trigger={null}
                    css={styles.sider}
                    onMouseEnter={sidebarMouseHoverStart}
                    onMouseLeave={sidebarMouseHoverEnd}
                >
                    <Menu
                        mode="inline"
                        style={{ height: "100%", borderRight: 0 }}
                        selectable={false}
                        onClick={handleMenuClick}
                        selectedKeys={getActiveMenuKey(location.pathname)}
                    >
                        {centreRoutes
                            .filter(route => route.sidebar)
                            .map(({ path, name, icon: Icon }) => {
                                return (
                                    <Menu.Item key={path} title={name}>
                                        <Icon />
                                        {sidebarHovered && <span>{name}</span>}
                                    </Menu.Item>
                                );
                            })}
                    </Menu>
                </Layout.Sider>
                <Layout.Content css={styles.content}>{children}</Layout.Content>
            </Layout>
        </Layout>
    );
};

export default HeaderSiderLayout;
