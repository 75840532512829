let apiURL = "http://localhost:8000";
let frontendURL = "http://localhost:3001";
let sentryDSN = "";
let firebaseConfig = {
    apiKey: "AIzaSyDh1w0shIGbkmzAOCvvwPP0O3TVeh9NKxQ",
    authDomain: "courtsite-development.firebaseapp.com",
    databaseURL: "https://courtsite-development.firebaseio.com",
    projectId: "courtsite-development",
    storageBucket: "courtsite-development.appspot.com",
    messagingSenderId: "464485368879",
    appId: "1:464485368879:web:245cb29a6d6da7601d32da",
};

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "production") {
    apiURL = "https://api-legacy.courtsite.my";
    sentryDSN = "";
    frontendURL = "https://www.courtsite.my";
    firebaseConfig = {
        apiKey: "AIzaSyAM4HOCloJcv4mLY-YV1HiW2sNpllrmE9k",
        authDomain: "www.courtsite.my",
        databaseURL: "https://courtsite-terraform.firebaseio.com",
        projectId: "courtsite-terraform",
        storageBucket: "courtsite-terraform.appspot.com",
        messagingSenderId: "574791846691",
    };
}

export default {
    ADMIN_API_URL: apiURL,
    FRONTEND_URL: frontendURL,
    SENTRY_DSN: sentryDSN,
    FIREBASE_CONFIG: firebaseConfig,
};
