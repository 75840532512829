import React from "react";
import { Route, Redirect } from "react-router-dom";

import LoadingPage from "components/LoadingPage/LoadingPage";

import { useAuth } from "config/firebase";

const PrivateRedirectRoute = ({
    component: Component,
    layout: Layout,
    ...rest
}) => {
    const auth = useAuth();

    const isLoggedIn = auth.user && auth.user.uid;
    if (!auth.isInitialised) {
        return <LoadingPage />;
    }

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Layout {...props} {...rest}>
                        <Component {...props} {...rest} />
                    </Layout>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRedirectRoute;
