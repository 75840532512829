// eslint-disable-next-line
import React from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Row, Col, Card, Switch, message } from "antd";

import { BulbOutlined } from "@ant-design/icons";

import { useCentre } from "hooks/centreConfig";
import dayjs from "dayjs";

import CreateLightingEventModal from "components/Modals/CreateLightingEventModal";
import { useModal } from "components/Modals/ModalProvider";

const GET_COURTS = gql`
    query centreByUuid($centreUuid: String!) {
        centreByUuid(uuid: $centreUuid) {
            courts {
                uuid
                name
                current_lighting_event {
                    uuid
                    start
                    end
                    booking_uuid
                }
            }
        }
    }
`;

const UPDATE_LIGHTING = gql`
    mutation updateLightingEventTime($lightingEventUuid: ID!, $end: DateTime) {
        updateLightingEventTime(
            lighting_event_uuid: $lightingEventUuid
            end: $end
        ) {
            uuid
        }
    }
`;

const styles = {
    card: css`
        text-align: center;
    `,
    bulbIcon: css`
        font-size: 30px;
        margin-top: 16px;
    `,
};

const LightingControlScreen = () => {
    const { activeCentre } = useCentre();
    const { initModal } = useModal();

    const { data, loading } = useQuery(GET_COURTS, {
        variables: {
            centreUuid: activeCentre.uuid,
        },
        fetchPolicy: "cache-and-network",
        pollInterval: 60000,
    });

    const [updateLightingEventTime, { updateLightingLoading }] = useMutation(
        UPDATE_LIGHTING,
        {
            refetchQueries: ["centreByUuid"],
        }
    );

    const deriveCourtLightingState = currentLightingEvent => {
        if (!currentLightingEvent) {
            return {
                on: false,
                state: "off",
                color: "",
                title: "Off",
                end: "N/A",
                uuid: currentLightingEvent && currentLightingEvent.uuid,
            };
        } else if (currentLightingEvent.booking_uuid) {
            return {
                on: true,
                state: "booking",
                color: "#91d5ff",
                title: "Booking",
                end: dayjs(currentLightingEvent.end).format("h:mma"),
                uuid: currentLightingEvent && currentLightingEvent.uuid,
            };
        } else {
            return {
                on: true,
                state: "override",
                color: "#ffa39e",
                title: "Override",
                end: dayjs(currentLightingEvent.end).format("h:mma"),
                uuid: currentLightingEvent && currentLightingEvent.uuid,
            };
        }
    };

    const handleToggleClick = court => {
        return () => {
            const lightingState = deriveCourtLightingState(
                court.current_lighting_event
            );
            if (lightingState.state === "override") {
                updateLightingEventTime({
                    variables: {
                        lightingEventUuid: lightingState.uuid,
                        end: dayjs().toISOString(),
                    },
                });
            } else if (lightingState.state === "booking") {
                message.error("Cant switch off the light for ongoing booking.");
            } else {
                initModal("createLighting", {
                    court: court,
                });
            }
        };
    };

    const courts =
        (data && data.centreByUuid && data.centreByUuid.courts) || [];

    const sortedCourts = courts.sort((a, b) => {
        if (a.name[0] < b.name[0]) {
            return -1;
        } else if (a.name[0] > b.name[0]) {
            return 1;
        } else if (a.name.length < b.name.length) {
            return -1;
        } else if (a.name.length > b.name.length) {
            return 1;
        } else if (a.name < b.name) {
            return -1;
        } else if (a.name > b.name) {
            return 1;
        } else {
            return 0;
        }
    });

    return (
        <Row gutter={[16, 16]}>
            {sortedCourts.map(court => {
                const lightingState = deriveCourtLightingState(
                    court.current_lighting_event
                );
                return (
                    <Col xs={12} sm={8} md={6} lg={4} xl={3} key={court.uuid}>
                        <Card
                            css={styles.card}
                            style={{
                                background: lightingState.color,
                            }}
                            cover={<BulbOutlined css={styles.bulbIcon} />}
                            loading={loading || updateLightingLoading}
                        >
                            <Card.Meta
                                title={court.name}
                                description={
                                    <div>
                                        <Row justify="center">
                                            <Col>{lightingState.title}</Col>
                                        </Row>
                                        <Row justify="center" gutter={[0, 16]}>
                                            <Col>
                                                End:&nbsp;
                                                {lightingState.end}
                                            </Col>
                                        </Row>
                                        <Row justify="center">
                                            <Col>
                                                <Switch
                                                    checked={lightingState.on}
                                                    onClick={handleToggleClick(
                                                        court
                                                    )}
                                                    style={{
                                                        backgroundColor:
                                                            lightingState.state ===
                                                                "override" &&
                                                            "#f5222d",
                                                    }}
                                                    loading={
                                                        loading ||
                                                        updateLightingLoading
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                );
            })}
            <CreateLightingEventModal />
        </Row>
    );
};

export default LightingControlScreen;
