import React from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Spin, Modal, message, Result } from "antd";

import { useModal } from "components/Modals/ModalProvider";
import InvoiceTemplate from "components/Templates/InvoiceTemplate";

import { useCentre } from "hooks/centreConfig";

const INVOICE_BY_UUID = gql`
    query invoiceByUuid($uuid: ID!) {
        invoiceByUuid(uuid: $uuid) {
            uuid
            status
            print_number
            amount_due
            created
            bookings {
                uuid
                start
                end
                price
                court {
                    name
                }
            }
            user {
                name
                email
                phone_number
            }
            source
        }
    }
`;

const ViewInvoiceByUUIDModal = () => {
    const { modalType, initialValues = {}, resetModal } = useModal();
    const { centreConfig } = useCentre();
    const shouldRender =
        modalType === "viewInvoice" &&
        typeof initialValues.invoiceUUID !== "undefined";

    const { data, loading, error } = useQuery(INVOICE_BY_UUID, {
        variables: {
            uuid: initialValues.invoiceUUID,
        },
        onError: () => {
            message.error("Failed to retrieve invoice details.");
        },
        fetchPolicy: "cache-and-network",
        skip: !shouldRender,
    });

    const handlePrintInvoiceClick = () => {
        window.open(
            `${window.location.origin}/print/invoice/${
                initialValues.invoiceUUID
            }?${encodeURIComponent(JSON.stringify(centreConfig))}`,
            "_blank"
        );
    };

    const handleModalClose = () => {
        resetModal();
    };

    const invoice = data && data.invoiceByUuid;

    return (
        <Modal
            visible={shouldRender}
            title={`Invoice || ${invoice && invoice.status}`}
            width={700}
            okText="Print"
            onOk={handlePrintInvoiceClick}
            cancelText="Close"
            okButtonProps={{ disabled: loading }}
            onCancel={handleModalClose}
            forceRender
        >
            <Spin spinning={loading}>
                {error ? (
                    <Result
                        status="error"
                        title="Some Error Occured"
                        subTitle="Failed to retrieve invoice details, please try again."
                    />
                ) : (
                    <InvoiceTemplate
                        invoice={invoice}
                        centreConfig={centreConfig}
                    />
                )}
            </Spin>
        </Modal>
    );
};

export default ViewInvoiceByUUIDModal;
