// eslint-disable-next-line
import React, { useEffect, useState } from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { Modal, Spin, Form, InputNumber, message, Typography } from "antd";
import dayjs from "dayjs";

import { useModal } from "components/Modals/ModalProvider";

const styles = {
    duration: css`
        text-align: center;
    `,
    durationText: css`
        margin-left: 16px;
    `,
};

const SCHEDULE_LIGHTING = gql`
    mutation scheduleLightingForCourt(
        $courtUuid: ID!
        $start: DateTime!
        $end: DateTime!
    ) {
        scheduleLightingForCourt(
            court_uuid: $courtUuid
            start: $start
            end: $end
        ) {
            uuid
        }
    }
`;

const CreateLightingEventModal = () => {
    const [form] = Form.useForm();
    const { modalType, initialValues, resetModal } = useModal();
    const courtDetails = (initialValues && initialValues.court) || {};

    const shouldRender =
        modalType === "createLighting" &&
        typeof initialValues.court !== "undefined";

    const [
        scheduleLightingEvent,
        { loading: scheduleLightingLoading },
    ] = useMutation(SCHEDULE_LIGHTING, {
        onCompleted: data => {
            const { scheduleLightingForCourt } = data;
            if (scheduleLightingForCourt.uuid) {
                form.resetFields();

                message.success(
                    `Lights turned on successfully for ${courtDetails.name}`,
                    2
                );
                return resetModal();
            }
        },
        onError: () => {
            message.error(
                "Failed to switch lights on due to upcoming booking."
            );
        },
        refetchQueries: ["centreByUuid"],
    });

    const handleCheckoutSubmit = () => {
        form.validateFields().then(values => {
            scheduleLightingEvent({
                variables: {
                    courtUuid: courtDetails.uuid,
                    start: dayjs().toISOString(),
                    end: dayjs()
                        .add(values.duration, "minute")
                        .toISOString(),
                },
            });
        });
    };

    const handleModalCancel = () => {
        form.resetFields();
        resetModal();
    };

    return (
        <Modal
            visible={shouldRender}
            title="Manual Lighting Control"
            onCancel={handleModalCancel}
            width={400}
            zIndex={1031}
            okText="Switch On"
            onOk={handleCheckoutSubmit}
            okButtonProps={{
                loading: scheduleLightingLoading,
            }}
            destroyOnClose
            forceRender
        >
            <Spin spinning={scheduleLightingLoading}>
                <Form form={form} name="cart_checkout">
                    <Form.Item>
                        <Typography.Text>
                            How long would you like to switch{" "}
                            <Typography.Text strong>
                                {courtDetails.name}
                            </Typography.Text>{" "}
                            on for?
                        </Typography.Text>
                    </Form.Item>
                    <Form.Item css={styles.duration}>
                        <Form.Item
                            name="duration"
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the duration.",
                                },
                            ]}
                        >
                            <InputNumber placeholder="60" />
                        </Form.Item>
                        <span css={styles.durationText}>Minutes</span>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default CreateLightingEventModal;
