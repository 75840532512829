// eslint-disable-next-line
import React from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { Row, Col, Typography, Button } from "antd";
import dayjs from "dayjs";

import { useModal } from "components/Modals/ModalProvider";

const { Text } = Typography;

const styles = {
    statusDot: css`
        height: 14px;
        width: 14px;
        border-radius: 14px;
    `,
    timeSpan: css`
        font-weight: 600;
        font-size: 24px;
        line-height: 1.35;
        color: #69c0ff;
    `,
    buttonRow: css`
        margin-top: 8px;
    `,
};

const EventItemPopover = ({ event }) => {
    const { user = {}, uuid, notes } = event;
    const { initModal } = useModal();

    const handleViewInvoiceButtonClick = () => {
        initModal("viewBookingInvoice", { bookingUUID: uuid });
    };

    return (
        <div style={{ width: "300px" }}>
            <Row align="middle" gutter={[0, 4]}>
                <Col span={2}>
                    <div
                        css={styles.statusDot}
                        style={{ backgroundColor: event.statusColor }}
                    />
                </Col>
                <Col span={22}>
                    <Text style={{ color: event.statusColor }}>
                        {event.status} | {event.source} Booking
                    </Text>
                </Col>
            </Row>
            <Row gutter={[0, 8]}>
                <Col span={2}>
                    <div />
                </Col>
                <Col span={22}>
                    <span css={styles.timeSpan}>
                        {dayjs(event.start).format("HH:mm")} -{" "}
                        {dayjs(event.end).format("HH:mm")}
                    </span>
                </Col>
            </Row>
            {event.reference_id && (
                <Row>
                    <Col span={2}>
                        <div />
                    </Col>
                    <Col span={22}>
                        <Typography.Text>
                            Booking ID: {event.reference_id}
                        </Typography.Text>
                    </Col>
                </Row>
            )}
            {user.name && (
                <Row>
                    <Col span={2}>
                        <div />
                    </Col>
                    <Col span={22}>
                        <Typography.Text>Name: {user.name}</Typography.Text>
                    </Col>
                </Row>
            )}
            <Row>
                <Col span={2}>
                    <div />
                </Col>
                <Col span={22}>
                    <Typography.Text>
                        Phone Number:{" "}
                        {user.phone_number ||
                            (event.invoice &&
                                event.invoice.user &&
                                event.invoice.user.phone_number)}
                    </Typography.Text>
                </Col>
            </Row>
            {notes && (
                <Row>
                    <Col span={2}>
                        <div />
                    </Col>
                    <Col span={22}>
                        <Typography.Text>Remarks: {notes}</Typography.Text>
                    </Col>
                </Row>
            )}
            <Row css={styles.buttonRow}>
                <Col span={2}>
                    <div />
                </Col>
                {event.invoice && (
                    <Col span={5}>
                        <Button
                            type="link"
                            style={{ paddingLeft: "0px" }}
                            onClick={handleViewInvoiceButtonClick}
                        >
                            View Invoice
                        </Button>
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default EventItemPopover;
