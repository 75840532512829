// eslint-disable-next-line
import React, { useState } from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import EventItem from "./EventItem";

dayjs.extend(isBetween);

const styles = {
    rootContainer: css`
        overflow-x: scroll;
    `,
    table: css`
        border-collapse: collapse;
        text-align: center;
    `,
};

const Scheduler = ({
    resources,
    events,
    config,
    handleTimeSlotSelect = () => {},
}) => {
    const [isSelecting, setIsSelecting] = useState(false);
    const [selectStartTime, setSelectStartTime] = useState();
    const [selectEndTime, setSelectEndTime] = useState();
    const [selectResource, setSelectResource] = useState();

    const {
        schedulerWidth,
        startTime: schedulerStartTime,
        endTime: schedulerEndTime,
        minutesPerTimeSlot,
    } = config;

    const timeHeaderArray = generateTimeHeaderArray(
        schedulerStartTime,
        schedulerEndTime
    );

    const timeSlotArray = generateTimeSlotArray(
        schedulerStartTime,
        schedulerEndTime,
        minutesPerTimeSlot
    );

    const handleMouseDown = (timeSlot, resourceID) => {
        return () => {
            setIsSelecting(true);
            setSelectStartTime(timeSlot.start);
            setSelectEndTime(timeSlot.end);
            setSelectResource(resourceID);
        };
    };

    const handleMouseOver = timeSlot => {
        return () => {
            if (!isSelecting) {
                return;
            } else {
                setSelectEndTime(timeSlot.end);
            }
        };
    };

    const handleMouseUp = () => {
        handleTimeSlotSelect(selectStartTime, selectEndTime, selectResource);

        setIsSelecting(false);
        setSelectStartTime();
        setSelectEndTime();
    };

    const isTimeSlotHighlighted = timeSlot => {
        const isHighlighted = dayjs(timeSlot.start).isBetween(
            dayjs(selectStartTime),
            dayjs(selectEndTime),
            null,
            "[)"
        );

        return isHighlighted;
    };

    return (
        <div style={{ width: schedulerWidth }} css={styles.rootContainer}>
            <table css={styles.table} style={{ width: `${schedulerWidth}px` }}>
                <tbody>
                    <tr>
                        <td
                            style={{
                                padding: "0px",
                                width: config.resourceWidth,
                                position: "absolute",
                                background:
                                    config.theme === "dark"
                                        ? "#141414"
                                        : "#fff",
                                borderBottom:
                                    config.theme === "dark"
                                        ? "solid 1px rgba(255, 255, 255, 0.65)"
                                        : "solid 1px rgba(0, 0, 0, 0.65)",
                                zIndex: 2,
                            }}
                        >
                            <table>
                                <tbody>
                                    <tr
                                        style={{ height: config.timeRowHeight }}
                                    >
                                        <td
                                            style={{
                                                border:
                                                    config.theme === "dark"
                                                        ? "1px solid rgba(255, 255, 255, 0.65)"
                                                        : "1px solid rgba(0, 0, 0, 0.65)",
                                                borderCollapse: "collapse",
                                                width: config.resourceWidth,
                                            }}
                                        >
                                            Resource Name
                                        </td>
                                    </tr>
                                    {resources.map((resource, index) => (
                                        <tr
                                            key={resource.id}
                                            style={{
                                                height:
                                                    config.resourceRowHeight,
                                            }}
                                        >
                                            <td
                                                key={resource.id}
                                                style={{
                                                    background:
                                                        index % 2 === 0
                                                            ? ""
                                                            : config.theme ===
                                                              "dark"
                                                                ? "rgba(255,255,255, 0.08)"
                                                                : "rgba(0,0,0,0.06)",
                                                    borderLeft:
                                                        config.theme === "dark"
                                                            ? "1px solid rgba(255, 255, 255, 0.65)"
                                                            : "1px solid rgba(0, 0, 0, 0.65)",
                                                    borderRight:
                                                        config.theme === "dark"
                                                            ? "1px solid rgba(255, 255, 255, 0.65)"
                                                            : "1px solid rgba(0, 0, 0, 0.65)",
                                                    width: config.resourceWidth,
                                                }}
                                            >
                                                {resource.name}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </td>
                        <td
                            style={{
                                padding: "0px",
                                borderBottom:
                                    config.theme === "dark"
                                        ? "1px solid rgba(255, 255, 255, 0.65)"
                                        : "1px solid rgba(0, 0, 0, 0.65)",
                            }}
                        >
                            <div
                                style={{
                                    paddingLeft: config.resourceWidth - 1,
                                    overflow: "scroll",
                                    width: schedulerWidth,
                                }}
                            >
                                <div
                                    style={{
                                        overflow: "hidden",
                                        margin: "0px",
                                        position: "relative",
                                        width:
                                            (60 / config.minutesPerTimeSlot) *
                                            config.timeSlotWidth *
                                            dayjs(config.endTime).diff(
                                                config.startTime,
                                                "hour"
                                            ),
                                    }}
                                >
                                    {resources.map((resource, index) => {
                                        return getEventsForResource(
                                            events,
                                            resource.id
                                        ).map(event => {
                                            const duration = dayjs(
                                                event.end
                                            ).diff(
                                                dayjs(event.start),
                                                "minute"
                                            );
                                            const numberOfCells = Math.ceil(
                                                duration / 15
                                            );

                                            const differenceWithStart = dayjs(
                                                event.start
                                            ).diff(
                                                dayjs(schedulerStartTime),
                                                "minute"
                                            );
                                            const leftOffset = Math.ceil(
                                                differenceWithStart / 15
                                            );
                                            return (
                                                <EventItem
                                                    key={event.uuid}
                                                    event={event}
                                                    height={
                                                        config.resourceRowHeight
                                                    }
                                                    width={
                                                        numberOfCells *
                                                        config.timeSlotWidth
                                                    }
                                                    leftOffset={
                                                        leftOffset *
                                                        config.timeSlotWidth
                                                    }
                                                    topOffset={
                                                        config.timeRowHeight +
                                                        index *
                                                            config.resourceRowHeight +
                                                        1
                                                    }
                                                />
                                            );
                                        });
                                    })}
                                    <table>
                                        <tbody>
                                            <tr
                                                style={{
                                                    height:
                                                        config.timeRowHeight,
                                                }}
                                            >
                                                {timeHeaderArray.map(time => (
                                                    <td
                                                        key={time}
                                                        style={{
                                                            border:
                                                                config.theme ===
                                                                "dark"
                                                                    ? "1px solid rgba(255, 255, 255, 0.65)"
                                                                    : "1px solid rgba(0, 0, 0, 0.65)",
                                                        }}
                                                        colSpan={`${60 /
                                                            config.minutesPerTimeSlot}`}
                                                    >
                                                        {dayjs(time)
                                                            .minute(0)
                                                            .format("h:mma")}
                                                    </td>
                                                ))}
                                            </tr>
                                            {resources.map(
                                                (resource, index) => (
                                                    <tr
                                                        key={resource.id}
                                                        style={{
                                                            height:
                                                                config.resourceRowHeight,
                                                            background:
                                                                index % 2 === 0
                                                                    ? ""
                                                                    : config.theme ===
                                                                      "dark"
                                                                        ? "rgba(255,255,255, 0.08)"
                                                                        : "rgba(0,0,0,0.06)",
                                                        }}
                                                    >
                                                        {timeSlotArray.map(
                                                            (
                                                                timeSlot,
                                                                index
                                                            ) => (
                                                                <td
                                                                    key={
                                                                        timeSlot.start +
                                                                        resource.id
                                                                    }
                                                                    style={{
                                                                        borderRight:
                                                                            index %
                                                                                4 ===
                                                                            3
                                                                                ? config.theme ===
                                                                                  "dark"
                                                                                    ? "1px solid rgba(255, 255, 255, 0.65)"
                                                                                    : "1px solid rgba(0, 0, 0, 0.65)"
                                                                                : "1px solid #e3e3e3",
                                                                        width:
                                                                            config.timeSlotWidth,
                                                                        background:
                                                                            isTimeSlotHighlighted(
                                                                                timeSlot
                                                                            ) &&
                                                                            resource.id ===
                                                                                selectResource
                                                                                ? "#69c0ff"
                                                                                : "transparent",
                                                                    }}
                                                                    onMouseDown={handleMouseDown(
                                                                        timeSlot,
                                                                        resource.id
                                                                    )}
                                                                    onMouseUp={
                                                                        handleMouseUp
                                                                    }
                                                                    onMouseOver={handleMouseOver(
                                                                        timeSlot
                                                                    )}
                                                                >
                                                                    <div />
                                                                </td>
                                                            )
                                                        )}
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Scheduler;

const generateTimeHeaderArray = (start, end) => {
    const timeHeaderArray = [];
    let timeHeaderStart = dayjs(start)
        .second(0)
        .millisecond(0);
    while (timeHeaderStart < end) {
        timeHeaderArray.push(timeHeaderStart);
        timeHeaderStart = dayjs(timeHeaderStart).add(1, "hour");
    }

    return timeHeaderArray;
};

const generateTimeSlotArray = (start, end, minutesPerTimeSlot) => {
    const slotArray = [];

    let timeSlotStart = dayjs(start)
        .second(0)
        .millisecond(0);
    while (timeSlotStart < end) {
        const timeSlot = {
            start: dayjs(timeSlotStart),
            end: dayjs(timeSlotStart).add(minutesPerTimeSlot, "minute"),
        };
        slotArray.push(timeSlot);
        timeSlotStart = timeSlot.end;
    }

    return slotArray;
};

const getEventsForResource = (events = [], resourceID) => {
    const filteredEvents = events.filter(event => {
        return event.court.uuid === resourceID;
    });

    return filteredEvents;
};
