// eslint-disable-next-line
import React from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { Popover, Typography } from "antd";

import EventItemPopover from "./EventItemPopover";

import { convertNumberToRinggitString } from "utils/utils";

const { Text } = Typography;

const styles = {
    container: css`
        border-radius: 19px;
        text-align: left;
        padding: 0px 16px;
        margin: 0 1px;
        user-select: none;
        cursor: pointer;
    `,
    eventDisplayText: css`
        color: #fff;
    `,
    eventItemPopover: css`
        z-index: 999;
    `,
};

const EventItem = ({ event = {}, width, height, leftOffset, topOffset }) => {
    return (
        <Popover
            content={<EventItemPopover event={event} />}
            placement="bottomLeft"
            trigger="hover"
        >
            <div
                style={{
                    width: width - 2,
                    height: height - 2,
                    left: leftOffset,
                    top: topOffset,
                    position: "absolute",
                    background: event.statusColor,
                }}
                css={styles.container}
            >
                <Text
                    css={styles.eventDisplayText}
                    ellipsis
                    style={{ lineHeight: `${height}px`, width: width - 32 }}
                >
                    {event.user && event.user.name} |{" "}
                    {convertNumberToRinggitString(event.price)} | {event.source}
                </Text>
            </div>
        </Popover>
    );
};

export default EventItem;
