import React from "react";

import { Layout } from "antd";

const BlankLayout = ({ children }) => {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Layout.Content>{children}</Layout.Content>
        </Layout>
    );
};

export default BlankLayout;
