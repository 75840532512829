// eslint-disable-next-line
import React, { useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Card, Skeleton, Result, Button, Row, Col } from "antd";

import DatePicker from "components/DatePicker/DatePicker";
import DateRangeQuickSelector from "components/DateRangeQuickSelector/DateRangeQuickSelector";

import { useCentre } from "hooks/centreConfig";

import { getTimeDistance } from "utils/utils";

const GENERATE_METABASE_DASHBOARD_URL = gql`
    query generateMetabaseDashboardURL(
        $centre_uuid: ID!
        $start: DateTime
        $end: DateTime
        $dashboardType: MetabaseDashboardType!
    ) {
        generateMetabaseDashboardURL(
            centre_uuid: $centre_uuid
            start: $start
            end: $end
            dashboardType: $dashboardType
        )
    }
`;

const tabList = [
    {
        key: "INVOICE_DASHBOARD",
        tab: "Revenue",
    },
    {
        key: "BOOKING_DASHBOARD",
        tab: "Utilisation",
    },
];

const AnalyticsScreen = () => {
    const { activeCentre } = useCentre();
    const [dateRange, setDateRange] = useState(getTimeDistance("today"));
    const [activeTabKey, setActiveTabKey] = useState(tabList[0].key);

    const { data, loading, error } = useQuery(GENERATE_METABASE_DASHBOARD_URL, {
        variables: {
            centre_uuid: activeCentre.uuid,
            start: dateRange[0],
            end: dateRange[1],
            dashboardType: activeTabKey,
        },
        fetchPolicy: "network-only",
    });

    const handleRangePickerChange = dates => {
        setDateRange(dates);
    };

    const handlePageReload = () => {
        window.location.reload();
    };

    const handleTabSelect = key => {
        setActiveTabKey(key);
    };

    if (error) {
        return (
            <Result
                status="500"
                title="500"
                subTitle="Sorry, some error occured, please try again."
                extra={
                    <Button type="primary" onClick={handlePageReload}>
                        Reload
                    </Button>
                }
            />
        );
    }

    return (
        <Card
            title="Analytics"
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={handleTabSelect}
            extra={
                <Row gutter={[16, 0]} justify="end">
                    <Col xs={0} md={16} flex="auto">
                        <DateRangeQuickSelector
                            handleRangePickerChange={handleRangePickerChange}
                        />
                    </Col>
                    <Col flex="336px">
                        <DatePicker.RangePicker
                            value={dateRange}
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={handleRangePickerChange}
                            allowClear={false}
                        />
                    </Col>
                </Row>
            }
        >
            <Skeleton loading={loading}>
                {!loading && data && (
                    <MetabaseDashboard
                        metabaseDashboardURL={data.generateMetabaseDashboardURL}
                    />
                )}
            </Skeleton>
        </Card>
    );
};

const MetabaseDashboard = ({ metabaseDashboardURL }) => {
    if (
        typeof metabaseDashboardURL !== "string" ||
        metabaseDashboardURL === ""
    ) {
        throw new Error("Invalid Metabase dashboard URL");
    }
    return (
        <iframe
            title="Metabase Generic Dashboard"
            src={metabaseDashboardURL}
            frameBorder={0}
            width={"100%"}
            height={736}
            allowtransparency="true"
        />
    );
};

export default AnalyticsScreen;
