// eslint-disable-next-line
import React, { useEffect } from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useParams, useLocation } from "react-router-dom";
import { Result, Skeleton, PageHeader, Button } from "antd";

import InvoiceTemplate from "components/Templates/InvoiceTemplate";

const styles = {
    page: css`
        max-width: 700px;
        width: 100%;
        margin: auto;
        background: #fff;
    `,
};

const INVOICE_BY_UUID = gql`
    query invoiceByUuid($uuid: ID!) {
        invoiceByUuid(uuid: $uuid) {
            status
            print_number
            amount_due
            created
            bookings {
                uuid
                start
                end
                price
                court {
                    name
                    centre {
                        uuid
                    }
                }
            }
            user {
                name
                email
                phone_number
            }
        }
    }
`;

const InvoicePrintScreen = () => {
    const { invoiceUUID } = useParams();
    const location = useLocation();

    const { data, loading, error } = useQuery(INVOICE_BY_UUID, {
        variables: {
            uuid: invoiceUUID,
        },
        fetchPolicy: "cache-and-network",
    });

    const centreConfig = JSON.parse(
        decodeURIComponent(location.search.substring(1))
    );

    const handleReloadButtonClick = () => {
        window.location.reload();
    };

    const invoice = data && data.invoiceByUuid;

    return (
        <PageHeader title="Tax Invoice" css={styles.page}>
            {error ? (
                <Result
                    status="error"
                    title="Some Error Occured"
                    subTitle="Failed to retrieve invoice details, please try again."
                    extra={
                        <Button
                            type="primary"
                            key="refresh"
                            onClick={handleReloadButtonClick}
                        >
                            Refresh
                        </Button>
                    }
                />
            ) : (
                <Skeleton active={true} loading={loading}>
                    <InvoiceTemplate
                        invoice={invoice}
                        centreConfig={centreConfig}
                    />
                </Skeleton>
            )}
        </PageHeader>
    );
};

export default InvoicePrintScreen;
