import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";

import { ApolloProvider } from "@apollo/react-hooks";
import { CachePersistor } from "apollo-cache-persist";
import { InMemoryCache } from "apollo-cache-inmemory";

import LoadingPage from "components/LoadingPage/LoadingPage";

import firebase, { ProvideAuth } from "config/firebase";
import settings from "config/settings";
import setupApollo from "config/apollo";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

if (settings.SENTRY_DSN) {
    Sentry.init({ dsn: settings.SENTRY_DSN });
}

const cache = new InMemoryCache();

const persistor = new CachePersistor({
    cache,
    storage: window.localStorage,
});

const client = setupApollo(cache);

cache.writeData({
    data: {
        cartItems: [],
    },
});

const Root = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        persistor
            .restore()
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                // eslint-disable-next-line
                console.log(error);
            });
    }, []);

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <ProvideAuth app={firebase}>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </ProvideAuth>
    );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
