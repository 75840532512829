import React, { useState } from "react";

import { Card, Form, Input, Button, Row, Col, message } from "antd";

import { useAuth } from "config/firebase";
const tabList = [
    {
        key: "UPDATEPASSWORD",
        tab: "Update Password",
    },
];

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 14, offset: 6 },
    },
};

const UserScreen = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { reauthenticateUser, updatePassword } = useAuth();

    const onFinish = async values => {
        try {
            setLoading(true);
            await reauthenticateUser(values.oldPassword);
            await updatePassword(values.newPassword);
            message.success("Password updated!");
            form.resetFields();
            setLoading(false);
        } catch (err) {
            const { code } = err;
            if (code === "auth/wrong-password") {
                message.error("Wrong Password.");
            } else if (code === "auth/weak-password") {
                message.error("Password is too weak.");
            } else {
                message.error("Unable to update password");
            }
            setLoading(false);
        }
    };

    return (
        <Row>
            <Col xs={24} xl={16} xxl={12}>
                <Card title="My Profile" tabList={tabList}>
                    <Form form={form} onFinish={onFinish} {...formItemLayout}>
                        <Form.Item
                            name="oldPassword"
                            label="Old Password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password disabled={loading} />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            label="New Password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password disabled={loading} />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label="Confirm New Password"
                            dependencies={["newPassword"]}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Please confirm your password!",
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            getFieldValue("newPassword") ===
                                                value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            "The two passwords that you entered do not match!"
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password disabled={loading} />
                        </Form.Item>
                        <Form.Item {...formItemLayoutWithOutLabel}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default UserScreen;
