import LoginScreen from "./LoginScreen/LoginScreen";
import ResetPasswordScreen from "./ResetPasswordScreen/ResetPasswordScreen";
import HomeScreen from "./HomeScreen/HomeScreen";

import AnalyticsScreen from "./AnalyticsScreen/AnalyticsScreen";
import BookingsScreen from "./BookingsScreen/BookingsScreen";
import InvoicesScreen from "./InvoicesScreen/InvoicesScreen";
import SchedulerScreen from "./SchedulerScreen/SchedulerScreen";
import InvoicePrintScreen from "./InvoicePrintScreen/InvoicePrintScreen";
import SettingsScreen from "./SettingsScreen/SettingsScreen";
import UserScreen from "./UserScreen/UserScreen";
import LightingControlScreen from "./LightingControlScreen/LightingControlScreen";

import {
    LineChartOutlined,
    CalendarOutlined,
    AccountBookOutlined,
    BarsOutlined,
    UserOutlined,
    SettingOutlined,
    BulbOutlined,
} from "@ant-design/icons";

import BlankLayout from "layouts/BlankLayout";
import HeaderSiderLayout from "layouts/HeaderSiderLayout";

const indexRoutes = [
    {
        path: "/login",
        name: "Login",
        component: LoginScreen,
        layout: BlankLayout,
    },
    {
        path: "/reset",
        name: "Reset Password",
        component: ResetPasswordScreen,
        layout: BlankLayout,
    },
    {
        path: "/print/invoice/:invoiceUUID",
        name: "Invoice",
        protected: "redirect",
        component: InvoicePrintScreen,
        layout: BlankLayout,
    },
    {
        path: "/",
        name: "Home",
        protected: "redirect",
        component: HomeScreen,
        layout: BlankLayout,
    },
];

export const centreRoutes = [
    {
        path: "/lighting",
        name: "Lighting",
        protected: "redirect",
        component: LightingControlScreen,
        icon: BulbOutlined,
        layout: HeaderSiderLayout,
        sidebar: true,
    },
    {
        path: "/scheduler",
        name: "Scheduler",
        protected: "redirect",
        component: SchedulerScreen,
        icon: CalendarOutlined,
        layout: HeaderSiderLayout,
        sidebar: true,
    },
    {
        path: "/analytics",
        name: "Analytics",
        protected: "redirect",
        component: AnalyticsScreen,
        icon: LineChartOutlined,
        layout: HeaderSiderLayout,
        sidebar: true,
    },
    {
        path: "/bookings",
        name: "Bookings",
        protected: "redirect",
        component: BookingsScreen,
        icon: BarsOutlined,
        layout: HeaderSiderLayout,
        sidebar: true,
    },
    {
        path: "/invoices",
        name: "Invoices",
        protected: "redirect",
        component: InvoicesScreen,
        icon: AccountBookOutlined,
        layout: HeaderSiderLayout,
        sidebar: true,
    },
    {
        path: "/config",
        name: "Settings",
        protected: "redirect",
        component: SettingsScreen,
        icon: SettingOutlined,
        layout: HeaderSiderLayout,
        sidebar: true,
    },
    {
        path: "/profile",
        name: "User Profile",
        protected: "redirect",
        component: UserScreen,
        icon: UserOutlined,
        layout: HeaderSiderLayout,
        sidebar: true,
    },
];

export default indexRoutes;
