// eslint-disable-next-line
import React from "react";

import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
    Link,
    useLocation,
} from "react-router-dom";
import { Menu, Breadcrumb, Row, Col } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import * as R from "ramda";

import HeaderSiderLayout from "layouts/HeaderSiderLayout";

import { centreRoutes } from "routes/indexRoutes";

import { ProvideModal } from "components/Modals/ModalProvider";

import { ProvideCentre } from "hooks/centreConfig";

import { getActiveMenuKey } from "utils/utils";

const CentreScreen = ({ centres }) => {
    const match = useRouteMatch();
    const location = useLocation();

    const menu = (
        <Menu>
            {centreRoutes.map(route => (
                <Menu.Item key={route.path}>
                    <Link to={`${match.url}${route.path}`}>{route.name}</Link>
                </Menu.Item>
            ))}
        </Menu>
    );

    const activeRoute = R.find(route =>
        R.includes(route.path, getActiveMenuKey(location.pathname))
    )(centreRoutes);

    return (
        <ProvideCentre centres={centres}>
            <ProvideModal>
                <HeaderSiderLayout>
                    <Row gutter={[0, 16]} style={{ marginLeft: "16px" }}>
                        <Col lg={0}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <HomeOutlined />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item overlay={menu}>
                                    {activeRoute && activeRoute.name}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Switch>
                        {centreRoutes.map(({ path, component: Component }) => (
                            <Route
                                path={`${match.path}${path}`}
                                render={props => (
                                    <Component
                                        {...props}
                                        breadcrumbMenu={menu}
                                    />
                                )}
                                key={path}
                            />
                        ))}
                        <Redirect to={`${match.path}${centreRoutes[0].path}`} />
                    </Switch>
                </HeaderSiderLayout>
            </ProvideModal>
        </ProvideCentre>
    );
};

export default CentreScreen;
