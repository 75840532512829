import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import gql from "graphql-tag";
import * as R from "ramda";

import firebase from "./firebase";
import settings from "./settings";

const GET_CART_ITEMS = gql`
    query GetCartItems {
        cartItems @client {
            uuid
            centre_uuid
        }
    }
`;

const typeDefs = gql`
    type AddToCartResponse {
        status: String!
        message: String!
    }

    type RemoveFromCartResponse {
        status: String!
        message: String!
    }

    type ClearCentreCartResponse {
        status: String!
        message: String!
    }

    type CartItem {
        uuid: ID!
        centre_uuid: ID!
    }

    extend type Booking {
        isInCart: Boolean!
    }

    extend type Query {
        cartItems: [CartItem]!
        getCartItemsByCentreuUuid(centre_uuid: ID!): [CartItem]!
    }

    extend type Mutation {
        addToCart(uuid: ID!, centre_uuid: ID!): AddToCartResponse
        removeFromCart(uuid: ID!): RemoveFromCartResponse
        clearCentreCart(centre_uuid: ID!): ClearCentreCartResponse
    }
`;

const resolvers = {
    Query: {
        getCartItemsByCentreUuid: (_, { centre_uuid }, { cache }) => {
            const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });
            if (!centre_uuid) {
                return [];
            } else {
                const filteredCartItems = R.filter(
                    R.propEq("centre_uuid", centre_uuid)
                )(cartItems);
                return filteredCartItems;
            }
        },
    },
    Mutation: {
        addToCart: (_, { uuid, centre_uuid }, { cache }) => {
            const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });

            const isItemInCart =
                R.filter(R.propEq("uuid", uuid))(cartItems).length > 0;
            if (isItemInCart) {
                return {
                    __typename: "AddToCartResponse",
                    code: "FAIL",
                    message: "Item already in cart.",
                };
            } else {
                const data = {
                    cartItems: [
                        ...cartItems,
                        {
                            __typename: "CartItem",
                            uuid: uuid,
                            centre_uuid: centre_uuid,
                        },
                    ],
                };

                cache.writeQuery({ query: GET_CART_ITEMS, data });
                return {
                    __typename: "AddToCartResponse",
                    code: "SUCCESS",
                    message: "Item added into cart successfully.",
                };
            }
        },
        removeFromCart: (_, { uuid }, { cache }) => {
            const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });
            const isItemInCart =
                R.filter(R.propEq("uuid", uuid))(cartItems).length > 0;
            if (!isItemInCart) {
                return {
                    __typename: "RemoveFromCartResponse",
                    code: "FAIL",
                    message: "Item does not exist in cart.",
                };
            } else {
                const data = {
                    cartItems: R.reject(R.propEq("uuid", uuid))(cartItems),
                };
                cache.writeQuery({ query: GET_CART_ITEMS, data });
                return {
                    __typename: "RemoveFromCartResponse",
                    code: "SUCCESS",
                    message: "Item removed from cart successfully.",
                };
            }
        },
        clearCentreCart: (_, { centre_uuid }, { cache }) => {
            const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });

            const data = {
                cartItems: R.reject(R.propEq("centre_uuid", centre_uuid))(
                    cartItems
                ),
            };
            cache.writeQuery({ query: GET_CART_ITEMS, data });
            return {
                __typename: "ClearCentreCartResponse",
                code: "SUCCESS",
                message: "Items removed from cart successfully.",
            };
        },
    },
    Booking: {
        isInCart: (booking, _, { cache }) => {
            const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });

            const isItemInCart =
                R.filter(R.propEq("uuid", booking.uuid))(cartItems).length > 0;
            return isItemInCart;
        },
    },
};

export default function setupApollo(cache) {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            // eslint-disable-next-line
            graphQLErrors.map(({ message, locations, path }) => {
                // eslint-disable-next-line no-console
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                );
            });
        }
        if (networkError) {
            // eslint-disable-next-line no-console
            console.log(`[Network error]: ${networkError}`);
        }
    });

    const request = async operation => {
        const { currentUser } = firebase.auth();
        if (currentUser) {
            const token = await currentUser.getIdToken();
            operation.setContext({
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }
    };

    const requestHandler = new ApolloLink(
        (operation, forward) =>
            new Observable(observer => {
                let handle;
                // eslint-disable-next-line
                Promise.resolve(operation)
                    .then(oper => request(oper))
                    .then(() => {
                        handle = forward(operation).subscribe({
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        });
                    })
                    .catch(observer.error.bind(observer));

                return () => {
                    // eslint-disable-next-line
                    if (handle) {
                        // eslint-disable-next-line
                        handle.unsubscribe;
                    }
                };
            })
    );

    const httpLink = new HttpLink({
        uri: `${settings.ADMIN_API_URL}/graphql`,
        fetchOptions: {},
        credentials: "same-origin",
    });

    const link = ApolloLink.from([errorLink, requestHandler, httpLink]);

    return new ApolloClient({ link, cache, typeDefs, resolvers });
}
