// eslint-disable-next-line
import React, { useState } from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import dayjs from "dayjs";
import { Card } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import ViewInvoiceByBookingModal from "components/Modals/ViewInvoiceByBookingModal";
import ViewInvoiceByUUIDModal from "components/Modals/ViewInvoiceByUUIDModal";
import DatePicker from "components/DatePicker/DatePicker";

import { useCentre } from "hooks/centreConfig";

import Scheduler from "./Scheduler";

const styles = {
    datePicker: css`
        margin: 0px 8px;
        width: 128px;
    `,
    breadcrumbContainer: css`
        @media only screen and (max-width: 575px) {
            margin-top: 16px;
        }
    `,
    rowContainer: css`
        padding: 0 16px 0 24px;
    `,
    addBookingIcon: css`
        margin-right: 16px;
        font-size: 20px;
        color: #1890ff;
    `,
};

const tabList = [
    {
        key: "BADMINTON",
        tab: "Badminton",
    },
    {
        key: "FUTSAL",
        tab: "Futsal",
    },
    {
        key: "PINGPONG",
        tab: "Table Tennis",
    },
];

const SchedulerScreen = () => {
    const { activeCentre, centreConfig } = useCentre();
    const [schedulerDate, setSchedulerDate] = useState(dayjs());
    const [activeTabKey, setActiveTabKey] = useState(tabList[0].key);
    const { schedulerStart = 0, schedulerEnd = 0 } = centreConfig;
    const schedulerStartTime = dayjs(schedulerDate)
        .hour(schedulerStart)
        .minute(0)
        .second(0)
        .millisecond(0);
    const schedulerHourDuration =
        schedulerStart < schedulerEnd
            ? schedulerEnd - schedulerStart
            : schedulerEnd - schedulerStart + 24;
    const schedulerEndTime = dayjs(schedulerStartTime).add(
        schedulerHourDuration,
        "hour"
    );
    const handleTabSelect = key => {
        setActiveTabKey(key);
    };

    const handleDatePickerSelect = date => {
        setSchedulerDate(date);
    };

    const handleDateNext = () => {
        const newDate = dayjs(schedulerDate).add(1, "day");
        setSchedulerDate(newDate);
    };

    const handleDatePrevious = () => {
        const newDate = dayjs(schedulerDate).subtract(1, "day");
        setSchedulerDate(newDate);
    };

    return (
        <Card
            title="Scheduler"
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={handleTabSelect}
            tabBarExtraContent={[
                <LeftOutlined key="keyPrevious" onClick={handleDatePrevious} />,
                <DatePicker
                    value={schedulerDate}
                    format="YYYY-MM-DD"
                    onChange={handleDatePickerSelect}
                    allowClear={false}
                    css={styles.datePicker}
                    key="datePicker"
                />,
                <RightOutlined key="dateNext" onClick={handleDateNext} />,
            ]}
        >
            <Scheduler
                activeCentre={activeCentre}
                schedulerStartTime={schedulerStartTime}
                schedulerEndTime={schedulerEndTime}
                activeTabKey={activeTabKey}
            />
            <ViewInvoiceByBookingModal />
            <ViewInvoiceByUUIDModal />
        </Card>
    );
};

export default SchedulerScreen;
