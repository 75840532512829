import React, { useContext, createContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { db } from "config/firebase";
import { getActiveCentre } from "utils/utils";

const centreContext = createContext();

export const ProvideCentre = ({ children, centres }) => {
    const centre = useProvideCentre(centres);

    return (
        <centreContext.Provider value={centre}>
            {children}
        </centreContext.Provider>
    );
};

export const useCentre = () => {
    return useContext(centreContext);
};

const useProvideCentre = centres => {
    const [loading, setLoading] = useState(false);
    const [centreConfig, setCentreConfig] = useState({});
    const params = useParams();

    const activeCentre = getActiveCentre(params.centreUuid, centres);

    useEffect(() => {
        const centreRef = db.collection("centres");

        const initCentreConfig = () => {
            centreRef.doc(activeCentre.uuid).set(
                {
                    config: {
                        name: activeCentre.name,
                    },
                    owner: [],
                },
                { merge: true }
            );
        };

        setLoading(true);
        const unsubscribe = centreRef
            .doc(`${activeCentre.uuid}`)
            .onSnapshot(snapshot => {
                if (snapshot.exists) {
                    const config = snapshot.data().config || {};
                    setCentreConfig(config);
                } else {
                    initCentreConfig();
                    setCentreConfig({});
                }
                setLoading(false);
            });

        return () => unsubscribe();
    }, [activeCentre.uuid, activeCentre.name]);

    return {
        activeCentre,
        centres,
        loading,
        centreConfig,
    };
};
