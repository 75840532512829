import React, { useState, useEffect, useContext, createContext } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as R from "ramda";

import settings from "config/settings";

const config = settings.FIREBASE_CONFIG;

// Add your Firebase credentials
export default firebase.initializeApp(config);

const db = firebase.firestore();

const timeStamp = firebase.firestore.FieldValue.serverTimestamp();

export { db, timeStamp };

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children, app }) {
    const auth = useProvideAuth(app);
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth(app) {
    const [user, setUser] = useState(null);
    const [isInitialised, setIsInitialised] = useState(false);

    const isCourtsite = R.includes("courtsite.my", user?.email || "");

    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const signin = (email, password) => {
        return app
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(response => {
                setUser(response.user);
                return response.user;
            });
    };

    const signup = (email, password) => {
        return app
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(response => {
                setUser(response.user);
                return response.user;
            });
    };

    const hasPassAuth = user => {
        if (!user) {
            return false;
        }
        for (let i = 0, l = user.providerData.length; i < l; i++) {
            const { providerId } = user.providerData[i];
            if (providerId === "password") {
                return true;
            }
        }
        return false;
    };

    const signout = () => {
        return app
            .auth()
            .signOut()
            .then(() => {
                setUser(false);
            });
    };

    const sendPasswordResetEmail = email => {
        return app
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                return true;
            });
    };

    const confirmPasswordReset = (code, password) => {
        return app
            .auth()
            .confirmPasswordReset(code, password)
            .then(() => {
                return true;
            });
    };

    const reauthenticateUser = password => {
        const credentials = firebase.auth.EmailAuthProvider.credential(
            user.email,
            password
        );
        return user.reauthenticateWithCredential(credentials);
    };

    const updatePassword = newPassword => {
        return user.updatePassword(newPassword);
    };

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = app.auth().onAuthStateChanged(user => {
            if (user) {
                setUser(user);
                setIsInitialised(true);
            } else {
                setUser(false);
                setIsInitialised(true);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    });

    useEffect(() => {
        const idTokenChange = app.auth().onIdTokenChanged(user => {
            if (user) {
                setUser(user);
            }
        });
        return () => idTokenChange();
    });

    // Return the user object and auth methods
    return {
        user,
        isCourtsite,
        isInitialised,
        signin,
        signup,
        hasPassAuth,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
        reauthenticateUser,
        updatePassword,
    };
}
