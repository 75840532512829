import React from "react";

import { Route } from "react-router-dom";

const DefaultRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            <Layout {...props} {...rest}>
                <Component {...props} {...rest} />
            </Layout>
        )}
    />
);

export default DefaultRoute;
