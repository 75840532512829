import React from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Spin, Modal, message, Result } from "antd";

import { useModal } from "components/Modals/ModalProvider";
import InvoiceTemplate from "components/Templates/InvoiceTemplate";
import { useCentre } from "hooks/centreConfig";

const GET_BOOKINGS_BY_UUID = gql`
    query getBookingsByUuid($centre_uuid: ID!, $booking_uuids: [ID!]!) {
        me {
            getBookingsByUuid(
                centre_uuid: $centre_uuid
                booking_uuids: $booking_uuids
            ) {
                uuid
                invoice {
                    uuid
                    status
                    print_number
                    amount_due
                    created
                    bookings {
                        uuid
                        start
                        end
                        price
                        court {
                            name
                        }
                    }
                    user {
                        name
                        email
                        phone_number
                    }
                    source
                }
            }
        }
    }
`;

const ViewInvoiceByUUIDModal = () => {
    const { modalType, initialValues, resetModal } = useModal();

    const { bookingUUID } = initialValues;
    const { activeCentre, centreConfig } = useCentre();

    const shouldRender =
        modalType === "viewBookingInvoice" &&
        typeof bookingUUID !== "undefined";

    const { data, loading, error } = useQuery(GET_BOOKINGS_BY_UUID, {
        variables: {
            centre_uuid: activeCentre.uuid,
            booking_uuids: [bookingUUID],
        },
        onError: () => {
            message.error("Failed to retrieve invoice details.");
        },
        fetchPolicy: "network-only",
        skip: !shouldRender,
    });

    const invoice =
        data &&
        data.me &&
        data.me.getBookingsByUuid &&
        data.me.getBookingsByUuid[0] &&
        data.me.getBookingsByUuid[0].invoice;

    const handlePrintInvoiceClick = () => {
        window.open(
            `${window.location.origin}/print/invoice/${
                invoice.uuid
            }?${encodeURIComponent(JSON.stringify(centreConfig))}`,
            "_blank"
        );
    };

    const handleModalClose = () => {
        resetModal();
    };

    return (
        <Modal
            visible={shouldRender}
            title={`Invoice || ${invoice && invoice.status}`}
            width={700}
            zIndex={1031}
            okText="Print"
            onOk={handlePrintInvoiceClick}
            cancelText="Close"
            onCancel={handleModalClose}
            okButtonProps={{ disabled: loading }}
        >
            <Spin spinning={loading}>
                {error ? (
                    <Result
                        status="error"
                        title="Some Error Occured"
                        subTitle="Failed to retrieve invoice details, please try again."
                    />
                ) : (
                    <InvoiceTemplate
                        invoice={invoice}
                        centreConfig={centreConfig}
                    />
                )}
            </Spin>
        </Modal>
    );
};

export default ViewInvoiceByUUIDModal;
