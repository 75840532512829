// eslint-disable-next-line
import React, { useState } from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { Form, Typography, Button, Input, message } from "antd";
import { useHistory } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";

import { useAuth } from "config/firebase";

const styles = {
    rootContainer: css`
        padding-top: 24px;

        @media only screen and (max-width: 575px) {
            padding: 0px;
        }
    `,
    modalContainer: css`
        margin: auto;
        margin-bottom: 64px;
        max-width: 400px;
        padding: 40px 8px 16px;
        border-radius: 4px;
        background: #000;
        border: 1px solid #e9e9e9;

        @media only screen and (max-width: 575px) {
            min-height: 100vh;
        }
    `,
    logoContainer: css`
        max-width: 320px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 20px;
    `,
    formContainer: css`
        max-width: 320px;
        margin: auto;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
    `,
    form: css`
        margin-top: 24px;
    `,
    logo: css`
        width: 48px;
        color: #84a3ef;
    `,
    subtitle: css`
        font-size: 18px;
    `,
};

const ResetPasswordScreen = () => {
    const [formLoading, setFormLoading] = useState(false);
    const { sendPasswordResetEmail } = useAuth();
    const history = useHistory();
    const onFinish = values => {
        setFormLoading(true);

        sendPasswordResetEmail(values.email)
            .then(() => {
                message.success(
                    `A message has been sent to ${values.email} with instructions to reset your password.`
                );
                history.push("/login");
            })
            .catch(error => {
                setFormLoading(false);
                handleResetError(error);
            });
    };

    const handleResetError = err => {
        switch (err.code) {
        case "auth/user-disabled":
            message.error(
                "Your account has been disabled. Please contact support@courtsite.my"
            );
            break;
        case "auth/invalid-email":
            message.error("E-mail address entered is invalid.");
            break;
        case "auth/user-not-found":
            message.error("Email entered is incorrect.");
            break;
        case "auth/wrong-password":
            message.error("Email entered is incorrect.");
            break;
        default:
            // auth/operation-not-allowed
            message.error(
                "An unknown error occurred, please try again later."
            );
            // eslint-disable-next-line no-console
            console.error(err);
            break;
        }
    };

    return (
        <div css={styles.rootContainer}>
            <div css={styles.modalContainer}>
                <div css={styles.formContainer}>
                    <Typography.Title level={3}>
                        Reset Your Password
                    </Typography.Title>
                    <Typography.Text css={styles.subtitle}>
                        Please enter your registered email address so we can
                        send you the reset instructions.
                    </Typography.Text>
                    <Form
                        name="reset_password"
                        onFinish={onFinish}
                        css={styles.form}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Username!",
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Email Address"
                                size="large"
                                disabled={formLoading}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                size="large"
                                loading={formLoading}
                            >
                                Send Email
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordScreen;
