import React, { useState, useEffect } from "react";

import { Table } from "antd";

import * as R from "ramda";

const ApolloPaginationTable = ({
    columns,
    apolloProps,
    defaultPageSize = 20,
}) => {
    const { data, loading, variables } = apolloProps;
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);

    const getConnectionFromResponse = R.pipe(R.values, R.head);

    const getNodesFromConnection = R.propOr([], "nodes");

    const getPageInfoFromConnection = R.propOr({}, "pageInfo");

    // `getConnectionFieldName` is similar to `getConnectionFromResponse`
    // but we are getting the name of the response field that contains
    // the Relay data.
    const getConnectionFieldName = R.pipe(R.keys, R.head);

    const shouldLoadMore = (nodes, variables, page, pageSize) => {
        const differentPageSize = variables.count !== pageSize;
        const needMoreResults = nodes.length < page * pageSize;
        return differentPageSize || needMoreResults;
    };

    const handleFetchData = ({ data, loading, variables, fetchMore }) => {
        if (loading) {
            return;
        }

        const connection = getConnectionFromResponse(data);
        const nodes = getNodesFromConnection(connection);

        if (nodes.length === 0) {
            return;
        }

        if (shouldLoadMore(nodes, variables, page, pageSize)) {
            const { endCursor, hasNextPage } = getPageInfoFromConnection(
                connection
            );
            if (hasNextPage) {
                fetchMore({
                    variables: {
                        ...variables,
                        cursor: endCursor,
                        count: pageSize,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const data = fetchMoreResult.me || {};

                        const fieldName = getConnectionFieldName(data);
                        const connection = getConnectionFromResponse(data);
                        const newNodes = getNodesFromConnection(connection);
                        const newPageInfo = getPageInfoFromConnection(
                            connection
                        );

                        // NOTE: probably simpler with https://github.com/mweststrate/immer
                        return newNodes.length
                            ? {
                                me: {
                                    ...previousResult.me,
                                    [fieldName]: {
                                        ...previousResult.me[fieldName],
                                        nodes: [
                                            ...previousResult.me[fieldName]
                                                .nodes,
                                            ...newNodes,
                                        ],
                                        pageInfo: {
                                            ...previousResult.me[fieldName]
                                                .pageInfo,
                                            ...newPageInfo,
                                        },
                                    },
                                },
                            }
                            : previousResult;
                    },
                });
            }
        }
    };

    const handlePaginationChange = pagination => {
        setPage(pagination.current);
        setPageSize(pagination.pageSize);

        handleFetchData(apolloProps);
    };

    useEffect(() => {
        setPage(1);
        setPageSize(defaultPageSize);
    }, [variables, defaultPageSize]);

    const connection = getConnectionFromResponse(data);
    const nodes = getNodesFromConnection(connection);
    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={nodes}
            onChange={handlePaginationChange}
            pagination={{
                current: page,
                pageSize: pageSize,
            }}
            rowKey="uuid"
        />
    );
};

export default ApolloPaginationTable;
