// eslint-disable-next-line
import React from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { Typography, Table, Descriptions, Row, Col } from "antd";
import dayjs from "dayjs";
import { sortBookings } from "utils/utils";
import settings from "config/settings";

const styles = {
    table: css`
        width: 100%;
    `,
    footerRemark: css`
        text-align: center;
        margin-top: 16px;
    `,
    summaryCell: css`
        padding: 8px;
        text-align: right;
    `,
    amount: css`
        display: flex;
    `,
    paymentLink: css`
        padding-top: 8px;
    `,
};

const columns = [
    {
        title: "Items",
        dataIndex: "courtName",
    },
    {
        title: "Booking Time",
        dataIndex: "start",
        render: text => <span>{dayjs(text).format("YYYY-MM-DD h:mm a")}</span>,
        align: "center",
        width: "168px",
    },
    {
        title: "Quantity",
        dataIndex: "duration",
        render: (text, record) => (
            <span>
                {dayjs(record.end)
                    .diff(dayjs(record.start), "hour", true)
                    .toFixed(1)}{" "}
                Hour(s)
            </span>
        ),
        align: "center",
        width: "100px",
    },
    {
        title: "Amount",
        dataIndex: "price",
        render: row => (
            <span css={styles.amount}>
                <Col span={6}> RM </Col>
                <Col span={18}>{row.toFixed(2)}</Col>
            </span>
        ),
        align: "right",
        width: "110px",
    },
];

const InvoiceTemplate = ({ invoice = {}, centreConfig = {}, classes }) => {
    const user = (invoice && invoice.user) || {};

    return (
        <Row>
            <Col span={24}>
                <Descriptions layout="horizontal" column={2} size="small">
                    <Descriptions.Item label="Issued by" span={2}>
                        {centreConfig.name}{" "}
                        {centreConfig.busRegNum &&
                            `(${centreConfig.busRegNum})`}
                    </Descriptions.Item>
                    <Descriptions.Item label="Invoice Number" span={1}>
                        INV-{invoice && invoice.print_number}
                    </Descriptions.Item>
                    {centreConfig.address && (
                        <Descriptions.Item label="Address" span={1}>
                            {centreConfig.address}
                        </Descriptions.Item>
                    )}

                    <Descriptions.Item label="Invoice Date" span={1}>
                        {dayjs(invoice.created).format("YYYY-MM-DD")}
                    </Descriptions.Item>
                    {centreConfig.phoneNumber && (
                        <Descriptions.Item label="Phone Number" span={1}>
                            {centreConfig.phoneNumber}
                        </Descriptions.Item>
                    )}

                    <Descriptions.Item label="Bill To" span={1}>
                        {user.name}
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={24}>
                <InvoiceItemsTable invoice={invoice} classes={classes} />
            </Col>
            {invoice && invoice.status === "UNPAID" && (
                <Col span={24} css={styles.paymentLink}>
                    <Descriptions layout="horizontal" column={2} size="small">
                        <Descriptions.Item label="Payment Link" span={2}>
                            <Typography.Text copyable>
                                {`${settings.FRONTEND_URL}/checkout/invoice/${invoice.uuid}`}
                            </Typography.Text>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            )}
            <Col span={24} css={styles.footerRemark}>
                This is a computer generated invoice. No signature required.
            </Col>
        </Row>
    );
};

const InvoiceItemsTable = ({ invoice }) => {
    const { bookings = [], amount_due } = invoice;
    const total = bookings.reduce((total, booking) => {
        return total + booking.price;
    }, 0);

    const discount = invoice.amount_due - total;

    const sortedBookings = sortBookings(bookings);

    return (
        <Table
            columns={columns}
            dataSource={sortedBookings}
            bordered
            pagination={false}
            size="small"
            rowKey="uuid"
            css={styles.table}
            summary={() => [
                <tr key="subtotal">
                    <td
                        colSpan={3}
                        style={{ padding: "8px", textAlign: "right" }}
                    >
                        Subtotal
                    </td>
                    <td
                        style={{
                            padding: "8px",
                            textAlign: "right",
                            display: "flex",
                        }}
                    >
                        <Col span={6}>RM</Col>
                        <Col span={18}>{total.toFixed(2)}</Col>
                    </td>
                </tr>,
                <tr key="discount">
                    <td
                        colSpan={3}
                        style={{ padding: "8px", textAlign: "right" }}
                    >
                        Discount
                    </td>
                    <td
                        style={{
                            padding: "8px",
                            textAlign: "right",
                            display: "flex",
                        }}
                    >
                        <Col span={6}>RM</Col>
                        <Col span={18}>{discount.toFixed(2)}</Col>
                    </td>
                </tr>,
                <tr key="total">
                    <td
                        colSpan={3}
                        style={{ padding: "8px", textAlign: "right" }}
                    >
                        Total
                    </td>
                    <td
                        style={{
                            padding: "8px",
                            textAlign: "right",
                            display: "flex",
                        }}
                    >
                        <Col span={6}>RM</Col>
                        <Col span={18}>
                            {amount_due && amount_due.toFixed(2)}
                        </Col>
                    </td>
                </tr>,
            ]}
        />
    );
};

export default InvoiceTemplate;
