import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Result, Button } from "antd";

import LoadingPage from "components/LoadingPage/LoadingPage";

import CentrePage from "routes/CentreScreen/CentreScreen";

import { useAuth } from "config/firebase";

const GET_CENTRES = gql`
    query me {
        me {
            name
            email
            centres {
                name
                uuid
                courts {
                    name
                    uuid
                    sport
                }
            }
        }
    }
`;

const HomeScreen = () => {
    const { signout } = useAuth();
    const { data, loading, error } = useQuery(GET_CENTRES, {
        fetchPolicy: "cache-and-network",
    });

    const handleReloadButtonClick = () => {
        window.location.reload();
    };

    const handleSignoutButtonClick = () => {
        signout();
    };

    if (loading) {
        return <LoadingPage />;
    }

    if (error) {
        return (
            <Result
                status="500"
                title="500"
                subTitle="Sorry, some error occured, please try again."
                extra={
                    <Button type="primary" onClick={handleReloadButtonClick}>
                        Refresh
                    </Button>
                }
            />
        );
    }

    if (data && data.me && data.me.centres && data.me.centres.length > 0) {
        const centres = data.me.centres;
        return (
            <Switch>
                <Route
                    path="/centre/:centreUuid"
                    render={props => (
                        <CentrePage {...props} centres={centres} />
                    )}
                />
                <Redirect to={`/centre/${centres[0].uuid}`} />
            </Switch>
        );
    }

    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
                <Button type="primary" onClick={handleSignoutButtonClick}>
                    Sign Out
                </Button>
            }
        />
    );
};

export default HomeScreen;
