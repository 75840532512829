import React, { useState, useContext, createContext } from "react";

const modalContext = createContext();

export const ProvideModal = ({ children }) => {
    const modal = useProvideModal();

    return (
        <modalContext.Provider value={modal}>{children}</modalContext.Provider>
    );
};

export const useModal = () => {
    return useContext(modalContext);
};

const useProvideModal = () => {
    const [modalType, setModalType] = useState("");
    const [initialValues, setInitialValues] = useState({});

    const initModal = (modalType = "", values = {}) => {
        setModalType(modalType);
        setInitialValues(values);
    };

    const resetModal = () => {
        setModalType("");
        return setInitialValues({});
    };

    return {
        modalType,
        initialValues,
        initModal,
        resetModal,
    };
};
