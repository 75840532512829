import React from "react";

import AutoSizer from "react-virtualized-auto-sizer";
import dayjs from "dayjs";
import Scheduler from "./Scheduler";

const CourtsiteScheduler = ({
    range = {},
    resources,
    events,
    handleTimeSlotSelect,
}) => {
    const config = {
        timeRowHeight: 40,
        resourceRowHeight: 36,
        timeSlotWidth: 40,
        resourceWidth: 140,

        minutesPerTimeSlot: 15,

        startTime: dayjs(range.start),
        endTime: dayjs(range.end),

        theme: "light",
    };
    return (
        <AutoSizer disableHeight={true}>
            {({ width }) => {
                return (
                    <Scheduler
                        resources={resources}
                        events={events}
                        handleTimeSlotSelect={handleTimeSlotSelect}
                        config={{ schedulerWidth: width, ...config }}
                    />
                );
            }}
        </AutoSizer>
    );
};

export default CourtsiteScheduler;
