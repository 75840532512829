import React from "react";

import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import { Alert } from "antd";

import PrivateRedirectRoute from "components/Routes/PrivateRedirectRoute";
import DefaultRoute from "components/Routes/DefaultRoute";
import indexRoutes from "routes/indexRoutes";

function App() {
    return (
        <Router>
            <Alert message="You are viewing the legacy, read-only (no changes allowed) admin panel for Courtsite." banner />

            <Switch>
                {indexRoutes.map((route, index) => {
                    if (route.protected === "redirect") {
                        return (
                            <PrivateRedirectRoute
                                path={route.path}
                                component={route.component}
                                layout={route.layout}
                                key={index}
                            />
                        );
                    }

                    return (
                        <DefaultRoute
                            path={route.path}
                            component={route.component}
                            layout={route.layout}
                            key={index}
                        />
                    );
                })}
                <Redirect to="/" />
            </Switch>
        </Router>
    );
}

export default App;
