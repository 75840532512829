import React, { useEffect, useState } from "react";

import {
    Card,
    Row,
    Col,
    Form,
    Input,
    Button,
    Spin,
    message,
    InputNumber,
    Select,
} from "antd";

import { useCentre } from "hooks/centreConfig";
import { db } from "config/firebase";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};

const tailLayout = {
    wrapperCol: {
        xs: { span: 24 },
        sm: { offset: 10, span: 14 },
    },
};

const amenitiesOptions = [
    { key: "wifi", label: "Wifi" },
    { key: "stringing", label: "Stringing Service" },
    { key: "parking", label: "Parking" },
];

const SettingsScreen = () => {
    const { activeCentre, centreConfig, loading } = useCentre();
    const [formLoading, setFormLoading] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            centreName: centreConfig.name || "",
            centrePhoneNumber: centreConfig.phoneNumber || "",
            centreAddress: centreConfig.address || "",
            centreBRN: centreConfig.busRegNum || "",
            schedulerStartHour: centreConfig.schedulerStart || 0,
            schedulerEndHour: centreConfig.schedulerEnd || 0,
        });
    }, [activeCentre.uuid, centreConfig, form]);

    const handleFormSubmit = values => {
        setFormLoading(true);
        const centreRef = db.collection("centres");
        centreRef
            .doc(`${activeCentre.uuid}`)
            .set(
                {
                    config: {
                        name: values.centreName || "",
                        phoneNumber: values.centrePhoneNumber || "",
                        address: values.centreAddress || "",
                        busRegNum: values.centreBRN || "",
                        schedulerStart: values.schedulerStartHour || 0,
                        schedulerEnd: values.schedulerEndHour || 0,
                        amenities: values.amenities || [],
                    },
                },
                { merge: true }
            )
            .then(() => {
                message.success("Settings updated successfully!");
                setFormLoading(false);
            })
            .catch(() => {
                message.error("Failed to update settings.");
                setFormLoading(false);
            });
    };

    return (
        <Row>
            <Col xs={24} xl={16} xxl={12}>
                <Card title="Settings">
                    <Spin spinning={loading || formLoading}>
                        <Form
                            form={form}
                            {...formItemLayout}
                            onFinish={handleFormSubmit}
                        >
                            <Form.Item name="centreName" label="Company Name">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="centrePhoneNumber"
                                label="Centre Phone Number"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="centreAddress"
                                label="Centre Address"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="centreBRN"
                                label="Company Registration Number"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Scheduler Start Hour">
                                <Form.Item name="schedulerStartHour" noStyle>
                                    <InputNumber min={0} max={23} />
                                </Form.Item>
                                <span>0 ~ 23</span>
                            </Form.Item>
                            <Form.Item label="Scheduler End Hour">
                                <Form.Item name="schedulerEndHour" noStyle>
                                    <InputNumber min={0} max={23} />
                                </Form.Item>
                                <span>0 ~ 23</span>
                            </Form.Item>
                            <Form.Item label="Amenities" name="amenities">
                                <Select mode="multiple">
                                    {amenitiesOptions.map(amenities => (
                                        <Select.Option
                                            value={amenities.key}
                                            key={amenities.key}
                                        >
                                            {amenities.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Card>
            </Col>
        </Row>
    );
};

export default SettingsScreen;
