// eslint-disable-next-line
import React, { useEffect, useState } from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Card, Row, Col, Badge, message, Button, Form, Input } from "antd";
import dayjs from "dayjs";

import DatePicker from "components/DatePicker/DatePicker";
import ApolloPaginationTable from "components/ApolloPaginationTable/ApolloPaginationTable";
import { useModal } from "components/Modals/ModalProvider";
import ViewInvoiceByUUIDModal from "components/Modals/ViewInvoiceByUUIDModal";
import DateRangeQuickSelector from "components/DateRangeQuickSelector/DateRangeQuickSelector";

import { useCentre } from "hooks/centreConfig";

import { getTimeDistance } from "utils/utils";

const styles = {
    searchButtonCol: css`
        text-align: right;
    `,
    marginRight: css`
        margin-right: 8px;
    `,
};

const GET_INVOICES_BY_CURSOR = gql`
    query getInvoicesByCursor(
        $count: Int!
        $cursor: ID!
        $filter: InvoiceFilter!
        $sort: String!
    ) {
        me {
            getInvoicesByCursor(
                first: $count
                after: $cursor
                filter: $filter
                sort: $sort
            ) @connection(key: "GetInvoices", filter: ["filter"]) {
                nodes {
                    uuid
                    print_number
                    user {
                        name
                        phone_number
                    }
                    amount_due
                    status
                    created
                }
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

const InvoicesScreen = () => {
    const { activeCentre } = useCentre();
    const [form] = Form.useForm();
    const { initModal } = useModal();
    const [dateRange, setDateRange] = useState(getTimeDistance("today"));

    const [
        getInvoicesQuery,
        { data, loading, error, fetchMore, variables },
    ] = useLazyQuery(GET_INVOICES_BY_CURSOR, {
        fetchPolicy: "network-only",
    });

    if (error) {
        message.error("Some error occured. Please try again.");
    }

    const handleSearchClick = values => {
        getInvoicesQuery({
            variables: {
                count: 21,
                cursor: "",
                sort: "DESC",
                filter: {
                    created_start: dayjs(dateRange[0]).toISOString(),
                    created_end: dayjs(dateRange[1]).toISOString(),
                    centre_uuid: activeCentre.uuid,
                    customer_name: values.guestName,
                    status: "PAID",
                    source: "ADMIN",
                },
            },
        });
    };

    const handleRangePickerChange = dates => {
        setDateRange(dates);
    };

    const handleResetFields = () => {
        form.resetFields();
    };

    const handleViewInvoiceClick = invoiceUUID => {
        return () => {
            initModal("viewInvoice", {
                invoiceUUID: invoiceUUID,
            });
        };
    };

    useEffect(() => {
        form.resetFields();
        getInvoicesQuery({
            variables: {
                count: 21,
                cursor: "",
                sort: "DESC",
                filter: {
                    created_start: dayjs(dateRange[0]).toISOString(),
                    created_end: dayjs(dateRange[1]).toISOString(),
                    centre_uuid: activeCentre.uuid,
                    customer_name: form.getFieldsValue().guestName,
                    status: "PAID",
                    source: "ADMIN",
                },
            },
        });
    }, [activeCentre, form, getInvoicesQuery, dateRange]);

    const columns = [
        {
            title: "Invoice ID",
            dataIndex: "print_number",
            key: "invoiceID",
            render: text => <span>INV-{text}</span>,
        },
        {
            title: "Invoice Date",
            dataIndex: "created",
            key: "invoiceDate",
            render: text => (
                <span>{dayjs(text).format("YYYY-MM-DD h:mma")}</span>
            ),
        },
        {
            title: "Name",
            dataIndex: ["user", "name"],
            key: "guestName",
        },
        {
            title: "Amount",
            dataIndex: "amount_due",
            key: "invoiceAmount",
            render: text => <span>RM {text.toFixed(2)}</span>,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "invoiceStatus",
            render: text => getPrettifiedInvoiceStatus(text),
        },
        {
            title: "Action",
            dataIndex: "uuid",
            key: "action",
            align: "right",
            render: text => (
                <Button type="link" onClick={handleViewInvoiceClick(text)}>
                    View
                </Button>
            ),
        },
    ];

    return (
        <Card
            title="Invoices"
            extra={
                <Row gutter={[16, 0]} justify="end">
                    <Col xs={0} md={16} flex="auto">
                        <DateRangeQuickSelector
                            handleRangePickerChange={handleRangePickerChange}
                        />
                    </Col>
                    <Col flex="336px">
                        <DatePicker.RangePicker
                            value={dateRange}
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={handleRangePickerChange}
                            allowClear={false}
                        />
                    </Col>
                </Row>
            }
        >
            <ViewInvoiceByUUIDModal />
            <Form
                form={form}
                name="invoice_search"
                labelCol={{
                    flex: "88px",
                }}
                wrapperCol={{
                    flex: "auto",
                }}
                labelAlign="left"
                initialValues={{
                    dateRange: getTimeDistance("today"),
                    bookingID: null,
                    guestName: null,
                    source: "ADMIN",
                }}
                onFinish={handleSearchClick}
            >
                <Row gutter={24} justify="space-between">
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item name="guestName" label="Name">
                            <Input placeholder="Customer Name" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} xl={8} css={styles.searchButtonCol}>
                        <Form.Item>
                            <Button
                                type="primary"
                                css={styles.marginRight}
                                htmlType="submit"
                            >
                                Search
                            </Button>
                            <Button onClick={handleResetFields}>Clear</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col span={24}>
                    <ApolloPaginationTable
                        apolloProps={{
                            data: {
                                getInvoicesByCursor:
                                    (data &&
                                        data.me &&
                                        data.me.getInvoicesByCursor) ||
                                    {},
                            },
                            loading,
                            fetchMore,
                            variables,
                        }}
                        columns={columns}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default InvoicesScreen;

const getPrettifiedInvoiceStatus = status => {
    let badge, text;
    switch (status) {
    case "PAID":
        badge = "success";
        text = "Paid";
        break;
    case "EXPIRED":
        badge = "error";
        text = "Cancelled";
        break;
    case "UNPAID":
        badge = "warning";
        text = "Unpaid";
        break;
    default:
        badge = "default";
        text = "UNKNOWN";
        break;
    }
    return <Badge status={badge} text={text} />;
};
