// eslint-disable-next-line
import React, { useEffect, useState } from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
    Card,
    Row,
    Col,
    Badge,
    message,
    Button,
    Form,
    Select,
    Input,
    Space,
} from "antd";
import dayjs from "dayjs";

import DatePicker from "components/DatePicker/DatePicker";
import ApolloPaginationTable from "components/ApolloPaginationTable/ApolloPaginationTable";
import { useModal } from "components/Modals/ModalProvider";
import ViewInvoiceByUUIDModal from "components/Modals/ViewInvoiceByUUIDModal";
import DateRangeQuickSelector from "components/DateRangeQuickSelector/DateRangeQuickSelector";
import CartItemButton from "components/CartItemButton/CartItemButton";

import { useCentre } from "hooks/centreConfig";

import { getTimeDistance } from "utils/utils";

const styles = {
    searchButtonCol: css`
        text-align: right;
    `,
    marginRight: css`
        margin-right: 8px;
    `,
    dateRangeQuickSelector: css`
        @media only screen and (max-width: 575px) {
            display: none;
        }
    `,
};

const GET_BOOKINGS_BY_CURSOR = gql`
    query getBookingsByCursor(
        $count: Int!
        $cursor: ID!
        $filter: BookingFilter!
        $sort: String!
    ) {
        me {
            getBookingsByCursor(
                first: $count
                after: $cursor
                filter: $filter
                sort: $sort
            ) @connection(key: "GetBookings", filter: ["filter"]) {
                nodes {
                    uuid
                    court {
                        uuid
                        name
                    }
                    start
                    end
                    price
                    user {
                        name
                        phone_number
                    }
                    status
                    invoice {
                        uuid
                        reference_id
                        status
                        user {
                            name
                            phone_number
                        }
                    }
                    source
                    isInCart @client
                }
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

const BookingsScreen = () => {
    const [form] = Form.useForm();
    const { activeCentre } = useCentre();
    const { initModal } = useModal();
    const [dateRange, setDateRange] = useState(getTimeDistance("today"));

    const [
        getBookingsQuery,
        { data, loading, fetchMore, variables },
    ] = useLazyQuery(GET_BOOKINGS_BY_CURSOR, {
        onError: () => {
            message.error("Some error occured. Please try again.");
        },
        notifyOnNetworkStatusChange: true,
    });

    const handleRangePickerChange = dates => {
        setDateRange(dates);
    };

    const handleViewInvoiceClick = invoiceUUID => {
        return () => {
            initModal("viewInvoice", {
                invoiceUUID: invoiceUUID,
            });
        };
    };

    const renderActionButton = booking => {
        const invoice = booking.invoice || {};
        return [
            <Button
                disabled={!(invoice.status === "PAID")}
                key="invoiceButton"
                type="link"
                onClick={handleViewInvoiceClick(invoice.uuid)}
            >
                Receipt
            </Button>,
        ];
    };

    const columns = [
        {
            title: "Time",
            dataIndex: "start",
            key: "start",
            render: text => (
                <span>{dayjs(text).format("YYYY-MM-DD h:mma")}</span>
            ),
        },
        {
            title: "Duration",
            dataIndex: "duration",
            render: (text, record) => (
                <span>
                    {dayjs(record.end).diff(dayjs(record.start), "hour")}{" "}
                    Hour(s)
                </span>
            ),
        },
        {
            title: "Court",
            dataIndex: ["court", "name"],
            key: "court",
        },
        {
            title: "Name",
            dataIndex: ["user", "name"],
            key: "guestName",
        },
        {
            title: "Phone",
            dataIndex: "userPhone",
            render: (text, record) => (
                <span>
                    {(record.user && record.user.phone_number) ||
                        (record.invoice &&
                            record.invoice.user &&
                            record.invoice.user.phone_number)}
                </span>
            ),
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            render: text => <span>RM {text.toFixed(2)}</span>,
        },
        {
            title: "Source",
            dataIndex: "source",
            key: "bookingSource",
            render: text => getHumanisedString(text),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "bookingStatus",
            render: text => getPrettifiedBookingStatus(text),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            align: "right",
            render: (text, record) => (
                <div style={{ textAlign: "right" }}>
                    {renderActionButton(record)}
                </div>
            ),
        },
    ];

    const handleSearchClick = values => {
        getBookingsQuery({
            variables: {
                count: 21,
                cursor: "",
                sort: "ASC",
                filter: {
                    start: dayjs(dateRange[0]).startOf("day"),
                    end: dayjs(dateRange[1]).endOf("day"),
                    centre_uuid: activeCentre.uuid,
                    court_uuid: values.court,
                    guest_name: values.guestName,
                    status: values.status,
                    source: values.source,
                },
            },
            fetchPolicy: "cache-and-network",
        });
    };

    const handleResetFields = () => {
        form.resetFields();
    };

    useEffect(() => {
        form.resetFields();
        getBookingsQuery({
            variables: {
                count: 21,
                cursor: "",
                sort: "ASC",
                filter: {
                    start: dayjs(dateRange[0]).startOf("day"),
                    end: dayjs(dateRange[1]).endOf("day"),
                    centre_uuid: activeCentre.uuid,
                    court_uuid: form.getFieldsValue().court,
                    guest_name: form.getFieldsValue().guestName,
                    status: form.getFieldsValue().status,
                    source: form.getFieldsValue().source,
                },
            },
            fetchPolicy: "cache-and-network",
        });
    }, [activeCentre, form, getBookingsQuery, dateRange]);

    return (
        <Card
            title="Bookings"
            extra={
                <Space>
                    <div css={styles.dateRangeQuickSelector}>
                        <DateRangeQuickSelector
                            handleRangePickerChange={handleRangePickerChange}
                        />
                    </div>
                    <DatePicker.RangePicker
                        value={dateRange}
                        format="YYYY-MM-DD"
                        onChange={handleRangePickerChange}
                        allowClear={false}
                    />
                    <CartItemButton activeCentre={activeCentre} />
                </Space>
            }
        >
            <ViewInvoiceByUUIDModal />
            <Form
                form={form}
                name="booking_search"
                labelCol={{
                    flex: "88px",
                }}
                wrapperCol={{
                    flex: "auto",
                }}
                labelAlign="left"
                initialValues={{
                    dateRange: getTimeDistance("today"),
                    bookingID: null,
                    guestName: null,
                    source: "ADMIN",
                }}
                onFinish={handleSearchClick}
            >
                <Row gutter={24} justify="space-between">
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item name="guestName" label="Name">
                            <Input placeholder="Customer Name" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item name="court" label="Court">
                            <Select placeholder="Court Name" allowClear>
                                {activeCentre.courts.map(court => (
                                    <Select.Option
                                        value={court.uuid}
                                        key={court.uuid}
                                    >
                                        {court.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item name="source" label="Source">
                            <Select placeholder="Online/Admin" allowClear>
                                <Select.Option value="WEB">
                                    Online
                                </Select.Option>
                                <Select.Option value="ADMIN">
                                    Admin
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item name="status" label="Status">
                            <Select
                                placeholder="Paid/Unpaid/Cancelled"
                                allowClear
                            >
                                <Select.Option value="ACTIVE">
                                    Paid
                                </Select.Option>
                                <Select.Option value="PENDING">
                                    Unpaid
                                </Select.Option>
                                <Select.Option value="CANCELLED">
                                    Cancelled
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12} xl={8} css={styles.searchButtonCol}>
                        <Form.Item>
                            <Button
                                type="primary"
                                css={styles.marginRight}
                                htmlType="submit"
                            >
                                Search
                            </Button>
                            <Button onClick={handleResetFields}>Clear</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col span={24}>
                    <ApolloPaginationTable
                        apolloProps={{
                            data: {
                                getBookingsByCursor:
                                    (data &&
                                        data.me &&
                                        data.me.getBookingsByCursor) ||
                                    {},
                            },
                            loading,
                            fetchMore,
                            variables,
                        }}
                        columns={columns}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default BookingsScreen;

const getHumanisedString = string =>
    string.toLowerCase().replace(/^\w/, c => c.toUpperCase());

const getPrettifiedBookingStatus = status => {
    let badge, text;
    switch (status) {
    case "ACTIVE":
        badge = "success";
        text = "Paid";
        break;
    case "CANCELLED":
        badge = "error";
        text = "Cancelled";
        break;
    case "PENDING":
        badge = "warning";
        text = "Unpaid";
        break;
    default:
        badge = "default";
        text = "UNKNOWN";
        break;
    }
    return <Badge status={badge} text={text} />;
};
