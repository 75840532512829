// eslint-disable-next-line
import React from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Badge } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";

import { useModal } from "components/Modals/ModalProvider";

const styles = {
    headerIcon: css`
        display: inline-block;
        font-size: 20px;
    `,
};

const GET_CART_ITEMS_BY_CENTRE = gql`
    query getCartItemsByCentreUuid($centre_uuid: ID!) {
        getCartItemsByCentreUuid(centre_uuid: $centre_uuid) @client {
            uuid
            centre_uuid
        }
    }
`;

const CartItemIcon = ({ activeCentre }) => {
    const { initModal } = useModal();

    const { data } = useQuery(GET_CART_ITEMS_BY_CENTRE, {
        variables: {
            centre_uuid: activeCentre.uuid,
        },
    });

    const handleCheckoutIconClick = () => {
        initModal("cartCheckout");
    };

    const cartItemNumber =
        data &&
        data.getCartItemsByCentreUuid &&
        data.getCartItemsByCentreUuid.length;

    return (
        <Badge count={cartItemNumber} showZero>
            <ShoppingCartOutlined
                css={styles.headerIcon}
                onClick={handleCheckoutIconClick}
            />
        </Badge>
    );
};

export default CartItemIcon;
