import React from "react";

const Logomark = props => (
    <svg data-name="Logo Mark" viewBox="0 0 83.36 99.14" {...props}>
        <title>Courtsite</title>
        <path
            d="M11.66,69.33A40.61,40.61,0,0,1,.89,41.3C1.07,19.82,18.55,1.67,40.23.49c24-1.31,44,17.67,44,41.17a40.94,40.94,0,0,1-3.69,17,2.64,2.64,0,0,1-3.64,1.27l-4.61-2.45a2.53,2.53,0,0,1-1.18-3.25,30.67,30.67,0,0,0,2.68-12.54A31.12,31.12,0,0,0,41.1,10.79C24.93,11.52,11.69,25,11.33,41a30.47,30.47,0,0,0,7.9,21.26,2.6,2.6,0,0,1-.13,3.54l-3.58,3.67a2.65,2.65,0,0,1-3.86-.1"
            fill="currentColor"
            fillRule="evenodd"
            transform="translate(-0.89 -0.43)"
        />
        <path
            d="M40.85,98.82,17.45,75.67a2.57,2.57,0,0,1,0-3.66l3.69-3.65a2.63,2.63,0,0,1,3.69,0l16,15.85a2.64,2.64,0,0,0,3.7,0s5.46-5,7-6.93c6.75-8.46,3.56-21.39-7.36-22.6-.48,0-1-.08-1.48-.09A15,15,0,0,1,28,42.45a14.61,14.61,0,0,1,4.39-13.08A15.09,15.09,0,0,1,49,26.73a14.41,14.41,0,0,1,8,10,20.16,20.16,0,0,1,.35,3.16,3,3,0,0,1-2.85,3c-3.37.2-6.94-1.46-7.68-3.89a4.59,4.59,0,0,0-3.2-3.25,4.47,4.47,0,0,0-4,1.07,4.29,4.29,0,0,0-1.28,3.9,4.59,4.59,0,0,0,4.52,3.52c13.62.12,23.46,9.39,23.46,22.87,0,5.62-1.12,10.09-3.89,14-3.59,5.12-17.83,17.65-17.83,17.65a2.66,2.66,0,0,1-3.7,0"
            fill="currentColor"
            transform="translate(-0.89 -0.43)"
        />
    </svg>
);

const Logotype = props => (
    <svg data-name="Logo Type" viewBox="0 0 267 48.3" {...props}>
        <title>Courtsite</title>
        <path
            d="M29.78,19.29a15,15,0,0,1,5.35,9.43H27.69a8,8,0,0,0-3-5,9.49,9.49,0,0,0-5.93-1.81A8.92,8.92,0,0,0,12,24.76q-2.7,2.85-2.7,8.39T12,41.57a8.9,8.9,0,0,0,6.76,2.88,9.33,9.33,0,0,0,5.93-1.84,8.07,8.07,0,0,0,3-5h7.44A15.07,15.07,0,0,1,29.78,47,16.64,16.64,0,0,1,18.9,50.54a17.71,17.71,0,0,1-8.69-2.09,14.82,14.82,0,0,1-5.93-6,19.13,19.13,0,0,1-2.15-9.28,19,19,0,0,1,2.15-9.22,14.82,14.82,0,0,1,5.93-6,17.6,17.6,0,0,1,8.69-2.09A16.73,16.73,0,0,1,29.78,19.29Z"
            transform="translate(-2.13 -2.3)"
            fill="currentColor"
        />
        <path
            d="M65.77,17.91a15,15,0,0,1,6.14,6,18.42,18.42,0,0,1,2.21,9.22,18.6,18.6,0,0,1-2.21,9.28,15,15,0,0,1-6.14,6,18.62,18.62,0,0,1-8.85,2.09,18.41,18.41,0,0,1-8.82-2.09A15.23,15.23,0,0,1,42,42.4a18.55,18.55,0,0,1-2.24-9.25A18.33,18.33,0,0,1,42,23.93a15.28,15.28,0,0,1,6.15-6,18.3,18.3,0,0,1,8.82-2.09A18.5,18.5,0,0,1,65.77,17.91ZM51.94,23.13a9.09,9.09,0,0,0-3.66,3.78,13,13,0,0,0-1.38,6.24,12.88,12.88,0,0,0,1.38,6.26,9.07,9.07,0,0,0,3.66,3.75,10.7,10.7,0,0,0,10,0,9.05,9.05,0,0,0,3.65-3.75,12.88,12.88,0,0,0,1.39-6.26,13,13,0,0,0-1.39-6.24,9.07,9.07,0,0,0-3.65-3.78,10.7,10.7,0,0,0-10,0Z"
            transform="translate(-2.13 -2.3)"
            fill="currentColor"
        />
        <path
            d="M112,16.25V50.11h-7.07V42.73a11,11,0,0,1-4.48,5.81A13.48,13.48,0,0,1,93,50.6a12.33,12.33,0,0,1-9.46-3.81Q79.93,43,79.93,35.91V16.25h7V35.17c0,3.08.77,5.44,2.33,7.1a8.35,8.35,0,0,0,6.39,2.49,8.72,8.72,0,0,0,6.7-2.7q2.52-2.72,2.52-7.87V16.25Z"
            transform="translate(-2.13 -2.3)"
            fill="currentColor"
        />
        <path
            d="M132,17.63a14,14,0,0,1,7.37-1.94v7.62H137.3a11.45,11.45,0,0,0-7.41,2.25c-1.86,1.49-2.8,4-2.8,7.4V50.11h-7V16.25h7v6.63A13.09,13.09,0,0,1,132,17.63Z"
            transform="translate(-2.13 -2.3)"
            fill="currentColor"
        />
        <path
            d="M162.82,44v6.09h-4.3q-5.42,0-8.2-2.62c-1.87-1.74-2.8-4.63-2.8-8.69V22.21h-4.86v-6h4.86V7.83h7.07v8.42h8.17v6h-8.17V38.92c0,1.93.35,3.26,1.07,4s2,1.1,3.78,1.1Z"
            transform="translate(-2.13 -2.3)"
            fill="currentColor"
        />
        <path
            d="M189.33,18.86A12.84,12.84,0,0,1,193.72,27h-6.63a7.16,7.16,0,0,0-2.37-4.45,7.5,7.5,0,0,0-5.13-1.69,6,6,0,0,0-4.09,1.26,4.29,4.29,0,0,0-1.44,3.41,3.41,3.41,0,0,0,1,2.58,7.26,7.26,0,0,0,2.62,1.53,44.29,44.29,0,0,0,4.45,1.23,44.72,44.72,0,0,1,6.18,1.79,9.88,9.88,0,0,1,4.05,2.91A8.14,8.14,0,0,1,194.09,41a8.46,8.46,0,0,1-3.22,6.88q-3.23,2.65-8.7,2.65-6.33,0-10.23-2.95a12.14,12.14,0,0,1-4.7-8.24h6.82a6.4,6.4,0,0,0,2.46,4.49,8.75,8.75,0,0,0,5.65,1.72,5.68,5.68,0,0,0,4-1.32,4.35,4.35,0,0,0,1.44-3.35,3.71,3.71,0,0,0-1.11-2.83,7.74,7.74,0,0,0-2.76-1.63,38.59,38.59,0,0,0-4.55-1.19,52.44,52.44,0,0,1-6-1.72,9.34,9.34,0,0,1-3.93-2.8,7.88,7.88,0,0,1-1.63-5.26,8.62,8.62,0,0,1,3.22-7q3.22-2.67,8.95-2.67Q185.74,15.82,189.33,18.86Z"
            transform="translate(-2.13 -2.3)"
            fill="currentColor"
        />
        <path
            d="M200.91,3.47a4.52,4.52,0,0,1,3.26-1.17,4.62,4.62,0,0,1,3.28,1.17,3.83,3.83,0,0,1,1.26,2.94,3.65,3.65,0,0,1-1.26,2.89,4.77,4.77,0,0,1-3.28,1.11,4.67,4.67,0,0,1-3.26-1.11,3.69,3.69,0,0,1-1.23-2.89A3.87,3.87,0,0,1,200.91,3.47Zm6.76,12.78V50.11h-7V16.25Z"
            transform="translate(-2.13 -2.3)"
            fill="currentColor"
        />
        <path
            d="M232.68,44v6.09h-4.3c-3.6,0-6.34-.87-8.2-2.62s-2.8-4.63-2.8-8.69V22.21h-4.85v-6h4.85V7.83h7.07v8.42h8.17v6h-8.17V38.92c0,1.93.36,3.26,1.08,4s2,1.1,3.78,1.1Z"
            transform="translate(-2.13 -2.3)"
            fill="currentColor"
        />
        <path
            d="M268.88,34.56h-26q.18,5.22,2.89,7.74a9.43,9.43,0,0,0,6.69,2.52A9.75,9.75,0,0,0,258.35,43a7.41,7.41,0,0,0,3-4.73h7.49A14.34,14.34,0,0,1,260.4,49a18.37,18.37,0,0,1-7.68,1.57A17.68,17.68,0,0,1,244,48.45a14.82,14.82,0,0,1-5.93-6A19,19,0,0,1,236,33.15a18.83,18.83,0,0,1,2.15-9.22,14.82,14.82,0,0,1,5.93-6,19.3,19.3,0,0,1,17.39,0,14.33,14.33,0,0,1,5.71,5.66,16.26,16.26,0,0,1,2,8A15.68,15.68,0,0,1,268.88,34.56Zm-7.95-8.76a8,8,0,0,0-3.44-3.32,10.76,10.76,0,0,0-4.77-1.07,9.83,9.83,0,0,0-6.88,2.52c-1.84,1.68-2.85,4.16-3,7.43h19.23A10.44,10.44,0,0,0,260.93,25.8Z"
            transform="translate(-2.13 -2.3)"
            fill="currentColor"
        />
    </svg>
);

const LogomarkWithType = props => (
    <svg data-name="Layer 1" viewBox="0 0 476.28 115.4" {...props}>
        <title>Courtsite</title>
        <path
            d="M189.12,220.5a47.62,47.62,0,0,1-12.4-32.63c.21-25,20.34-46.13,45.3-47.5a48,48,0,0,1,46.45,67.68,3,3,0,0,1-4.2,1.48l-5.3-2.86a3,3,0,0,1-1.36-3.77,36,36,0,1,0-68.86-15.42,35.68,35.68,0,0,0,9.1,24.75,3,3,0,0,1-.16,4.11l-4.12,4.28A3,3,0,0,1,189.12,220.5Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M222.74,254.82l-27-26.95a3,3,0,0,1,0-4.25l4.25-4.25a3,3,0,0,1,4.25,0l18.45,18.45a3,3,0,0,0,4.25,0s6.3-5.82,8.07-8.06c7.78-9.85,4.1-24.9-8.47-26.31a16,16,0,0,0-1.71-.1,17.37,17.37,0,0,1-16.94-14.14,17.08,17.08,0,0,1,24.17-18.29,16.7,16.7,0,0,1,9.22,11.64,23.66,23.66,0,0,1,.42,3.67,3.48,3.48,0,0,1-3.29,3.54c-3.89.22-8-1.72-8.85-4.54a5.32,5.32,0,0,0-3.68-3.78,5,5,0,0,0-6.14,5.78,5.29,5.29,0,0,0,5.21,4.09c15.68.15,27,10.94,27,26.63,0,6.54-1.29,11.74-4.47,16.33-4.14,6-20.55,20.54-20.55,20.54A3,3,0,0,1,222.74,254.82Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M332.63,189.87a20,20,0,0,1,7.16,12.62h-10a10.6,10.6,0,0,0-4-6.7,12.66,12.66,0,0,0-7.94-2.43,11.94,11.94,0,0,0-9.05,3.83q-3.62,3.83-3.62,11.22t3.62,11.27a11.87,11.87,0,0,0,9.05,3.87,12.5,12.5,0,0,0,7.94-2.47,10.75,10.75,0,0,0,4-6.74h10A20.17,20.17,0,0,1,332.63,227q-5.76,4.69-14.56,4.69a23.75,23.75,0,0,1-11.64-2.79,19.92,19.92,0,0,1-7.93-8.06,28,28,0,0,1,0-24.76,19.86,19.86,0,0,1,7.93-8.06,23.64,23.64,0,0,1,11.64-2.8Q326.88,185.22,332.63,189.87Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M380.8,188a20,20,0,0,1,8.22,8.06,27.37,27.37,0,0,1,0,24.76,20.06,20.06,0,0,1-8.22,8.06A24.91,24.91,0,0,1,369,231.69a24.66,24.66,0,0,1-11.8-2.79,20.4,20.4,0,0,1-8.22-8.1,24.66,24.66,0,0,1-3-12.39,24.38,24.38,0,0,1,3-12.33,20.37,20.37,0,0,1,8.22-8.06,24.55,24.55,0,0,1,11.8-2.8A24.8,24.8,0,0,1,380.8,188Zm-18.51,7a12.08,12.08,0,0,0-4.89,5.06,19.86,19.86,0,0,0,0,16.74,12.06,12.06,0,0,0,4.89,5,14.29,14.29,0,0,0,13.33,0,12.12,12.12,0,0,0,4.89-5,19.86,19.86,0,0,0,0-16.74,12.14,12.14,0,0,0-4.89-5.06,14.29,14.29,0,0,0-13.33,0Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M442.61,185.8v45.32h-9.46v-9.87a14.62,14.62,0,0,1-6,7.77,17.94,17.94,0,0,1-9.95,2.76q-7.9,0-12.67-5.1t-4.77-14.56V185.8h9.37v25.33q0,6.17,3.13,9.5a11.16,11.16,0,0,0,8.55,3.33,11.66,11.66,0,0,0,9-3.62q3.38-3.61,3.37-10.52v-24Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M469.47,187.65a18.61,18.61,0,0,1,9.87-2.59v10.19h-2.8q-6.17,0-9.91,3t-3.75,9.91v23h-9.37V185.8h9.37v8.88A17.49,17.49,0,0,1,469.47,187.65Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M510.7,223v8.14H505q-7.24,0-11-3.5T490.22,216V193.77h-6.49v-8h6.49V174.53h9.46V185.8h10.94v8H499.68v22.38q0,3.87,1.44,5.34t5.06,1.49Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M546.19,189.29a17.22,17.22,0,0,1,5.88,10.9h-8.88a9.6,9.6,0,0,0-3.17-6,10.08,10.08,0,0,0-6.87-2.27,8.08,8.08,0,0,0-5.47,1.69,5.76,5.76,0,0,0-1.93,4.57,4.54,4.54,0,0,0,1.4,3.45,9.85,9.85,0,0,0,3.49,2.06,60.68,60.68,0,0,0,6,1.64,58.63,58.63,0,0,1,8.26,2.39,13.33,13.33,0,0,1,5.43,3.91,10.87,10.87,0,0,1,2.26,7.27,11.37,11.37,0,0,1-4.31,9.22q-4.32,3.53-11.64,3.53-8.48,0-13.7-3.95a16.23,16.23,0,0,1-6.29-11h9.13a8.62,8.62,0,0,0,3.29,6,11.78,11.78,0,0,0,7.57,2.3,7.54,7.54,0,0,0,5.3-1.77,5.8,5.8,0,0,0,1.93-4.48,4.93,4.93,0,0,0-1.48-3.78,10.09,10.09,0,0,0-3.7-2.18,50,50,0,0,0-6.08-1.61,67.17,67.17,0,0,1-8-2.3,12.62,12.62,0,0,1-5.27-3.74,10.62,10.62,0,0,1-2.18-7,11.54,11.54,0,0,1,4.32-9.33q4.32-3.59,12-3.58Q541.38,185.22,546.19,189.29Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M561.69,168.69a6.06,6.06,0,0,1,4.36-1.56,6.19,6.19,0,0,1,4.39,1.56,5.14,5.14,0,0,1,1.69,3.95,4.88,4.88,0,0,1-1.69,3.86,6.38,6.38,0,0,1-4.39,1.48,6.25,6.25,0,0,1-4.36-1.48,4.94,4.94,0,0,1-1.65-3.86A5.21,5.21,0,0,1,561.69,168.69Zm9,17.11v45.32h-9.37V185.8Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M604.22,223v8.14h-5.76q-7.25,0-11-3.5T583.74,216V193.77h-6.5v-8h6.5V174.53h9.46V185.8h10.94v8H593.2v22.38q0,3.87,1.44,5.34T599.7,223Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
        <path
            d="M652.67,210.31H617.8q.24,7,3.86,10.36a12.66,12.66,0,0,0,9,3.37,13.07,13.07,0,0,0,7.94-2.38,9.89,9.89,0,0,0,4-6.33h10a19.24,19.24,0,0,1-11.27,14.27A24.66,24.66,0,0,1,631,231.69a23.69,23.69,0,0,1-11.64-2.79,20,20,0,0,1-7.94-8.06,28.14,28.14,0,0,1,0-24.76A19.88,19.88,0,0,1,619.4,188a25.8,25.8,0,0,1,23.28-.05,19.28,19.28,0,0,1,7.65,7.57,23.39,23.39,0,0,1,2.34,14.77ZM642,198.59a10.63,10.63,0,0,0-4.61-4.45,14.31,14.31,0,0,0-6.37-1.43,13.14,13.14,0,0,0-9.21,3.37q-3.71,3.38-4,9.95h25.74A14,14,0,0,0,642,198.59Z"
            transform="translate(-176.72 -140.3)"
            fill="currentColor"
        />
    </svg>
);

export { Logomark, Logotype, LogomarkWithType };
