import React from "react";

import { Space, Button } from "antd";

import { getTimeDistance } from "utils/utils";

const DateRangeQuickSelector = ({ handleRangePickerChange = () => {} }) => {
    const handleRangeTypeButtonClick = type => {
        return () => {
            handleRangePickerChange(getTimeDistance(type));
        };
    };
    return (
        <Space size="small">
            <Button type="link" onClick={handleRangeTypeButtonClick("month")}>
                This Month
            </Button>
            <Button type="link" onClick={handleRangeTypeButtonClick("week")}>
                This Week
            </Button>
            <Button type="link" onClick={handleRangeTypeButtonClick("today")}>
                Today
            </Button>
        </Space>
    );
};

export default DateRangeQuickSelector;
