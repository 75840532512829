// eslint-disable-next-line
import React from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { useHistory, useParams, useLocation } from "react-router-dom";

import { Select, Row, Col, Dropdown, Menu, Layout } from "antd";
import {
    UserOutlined,
    ExportOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";

import { useAuth } from "config/firebase";
import { Logomark } from "assets/vectors/Logo";

const styles = {
    headerContainer: css`
        padding: 0;
        height: 64px;
        background: #fff;
        position: fixed;
        width: 100%;
        z-index: 99;
        box-shadow: 0 2px 8px #f0f1f2;
    `,
    rowContainer: css`
        margin: 0px 16px;
    `,
    logoContainer: css`
        height: 64px;
        text-align: left;
        padding-top: 8px;
    `,
    logomark: css`
        color: #1890ff;
        height: 32px;
        margin-left: 16px;
    `,
    centreSelect: css`
        width: calc(100vw - 88px);
        max-width: 320px;
    `,
    headerIcon: css`
        display: inline-block;
        font-size: 20px;
    `,
};

const Header = ({ activeCentre, centres }) => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const { signout } = useAuth();

    const handleCentreSelect = value => {
        const newPathname = location.pathname.replace(params.centreUuid, value);

        history.push(newPathname);
    };

    const handleLogoutClick = () => {
        signout();
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={handleLogoutClick}>
                <ExportOutlined /> Log out{" "}
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout.Header css={styles.headerContainer}>
            <Row align="middle" css={styles.rowContainer}>
                <Col flex="80px" css={styles.logoContainer} xs={0} sm={20}>
                    <Logomark css={styles.logomark} />
                </Col>
                <Col flex="auto">
                    <Select
                        css={styles.centreSelect}
                        value={activeCentre.uuid}
                        size="large"
                        onSelect={handleCentreSelect}
                    >
                        {centres.map(centre => (
                            <Select.Option
                                value={centre.uuid}
                                key={centre.uuid}
                            >
                                {centre.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col flex="40px" xs={6}>
                    <Dropdown overlay={menu}>
                        <div>
                            <UserOutlined css={styles.headerIcon} />
                            <CaretDownOutlined />
                        </div>
                    </Dropdown>
                </Col>
            </Row>
        </Layout.Header>
    );
};

export default Header;
