// eslint-disable-next-line
import React, { useState } from "react";

import { Redirect, Link } from "react-router-dom";

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Form, Button, Input, Typography, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { useAuth } from "config/firebase";
import { Logomark } from "assets/vectors/Logo";

const styles = {
    rootContainer: css`
        padding-top: 24px;

        @media only screen and (max-width: 575px) {
            padding: 0px;
        }
    `,
    modalContainer: css`
        margin: auto;
        margin-bottom: 64px;
        max-width: 400px;
        padding: 40px 8px 16px;
        border-radius: 4px;
        background: #fff;
        border: 1px solid #e9e9e9;

        @media only screen and (max-width: 575px) {
            min-height: 100vh;
        }
    `,
    logoContainer: css`
        max-width: 320px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 20px;
    `,
    formContainer: css`
        max-width: 320px;
        margin: auto;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
    `,
    form: css`
        margin-top: 24px;
    `,
    logo: css`
        width: 48px;
        color: #84a3ef;
    `,
    subtitle: css`
        font-size: 18px;
    `,
};

const LoginScreen = () => {
    const auth = useAuth();
    const [formLoading, setFormLoading] = useState(false);

    const onFinish = values => {
        setFormLoading(true);
        auth.signin(values.username, values.password)
            .then(() => {
                message.success("Login successful!", 1);
            })
            .catch(error => {
                handleLoginError(error);
                setFormLoading(false);
            });
    };

    const handleLoginError = err => {
        switch (err.code) {
        case "auth/user-disabled":
            message.error(
                "Your account has been disabled. Please contact support@courtsite.my"
            );
            break;
        case "auth/invalid-email":
            message.error("E-mail address entered is invalid.");
            break;
        case "auth/user-not-found":
            message.error(
                "There is no user record corresponding to this username."
            );
            break;
        case "auth/wrong-password":
            message.error("Email/Password entered is incorrect.");
            break;
        default:
            // auth/operation-not-allowed
            message.error(
                "An unknown error occurred, please try again later."
            );
            // eslint-disable-next-line no-console
            console.error(err);
            break;
        }
    };

    const isLoggedIn = auth.user && auth.user.uid;

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <div css={styles.rootContainer}>
            <div css={styles.modalContainer}>
                <div css={styles.logoContainer}>
                    <Logomark css={styles.logo} />
                </div>
                <div css={styles.formContainer}>
                    <Typography.Title level={3}>
                        Courtsite Admin
                    </Typography.Title>
                    <Typography.Text css={styles.subtitle}>
                        Cloud Facility Management
                    </Typography.Text>
                    <Form
                        name="normal_login"
                        onFinish={onFinish}
                        css={styles.form}
                    >
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Username!",
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Username"
                                size="large"
                                disabled={formLoading}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Password!",
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                type="password"
                                placeholder="Password"
                                size="large"
                                disabled={formLoading}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                size="large"
                                loading={formLoading}
                            >
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                    <Link to="/reset">Reset password</Link>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
